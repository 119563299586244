import { ArrowOutward, CameraAlt } from "@mui/icons-material";
import { TabList } from "@mui/lab";
import { Card, IconButton, Stack, styled, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import { H4, H5, Small, Span, Tiny } from "components/Typography";
import AvatarBadge from "components/avatars/AvatarBadge";
import Bratislava from "icons/Bratislava";
import DateRange, { clock, person } from "icons/DateRange";
import MapMarkerIcon from "icons/MapMarkerIcon";
import numeral from "numeral";
import { Fragment, useState } from "react";
import { Outlet } from "react-router-dom"; // styled components

import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";

import { FormattedDate } from "components/shared/FormattedDate";
import TableCell from "@mui/material/TableCell";
import CountryFlag from "components/shared/CountryFlag";
import LastActive from "components/shared/LastActive";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import OnlineStatus from "components/shared/OnlineStatus";
import { COMMON_API_BASE_URL } from "config";
import UserService from "services/UserService";

const ContentWrapper = styled(Box)(({ theme }) => ({
  zIndex: 1,
  padding: 24,
  marginTop: 55,
  position: "relative",
}));
const CoverPicWrapper = styled(Box)(() => ({
  top: 0,
  left: 0,
  height: 125,
  width: "100%",
  overflow: "hidden",
  position: "absolute",
  backgroundColor: "#C6D3ED",
}));
// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));
const StyledFlexBetween = styled(FlexBetween)(() => ({
  margin: "auto",
  flexWrap: "wrap",
}));
const StyledTabList = styled(TabList)(({ theme }) => ({
  // paddingLeft: 16,
  // paddingRight: 16,
  [theme.breakpoints.up("sm")]: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
}));

// -------------------------------------------------

// -------------------------------------------------
const Layout = ({ children, handleTabList, tabValue }) => {
  // get data from usercontext api
  const { userdata } = useContext(UserContext);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    // You may want to perform additional checks here, such as file type and size validation.

    setSelectedImage(file);

    // Upload the image directly after selection
    const formData = new FormData();
    formData.append("profile_pic", file);

    try {
      // call api
      const resp = await UserService.updateImage(userdata._id, formData);
      if (resp.status) {
        setSelectedImage(resp.data.md);
      }
    } catch (error) {
      // Handle errors
      // console.error("Error uploading image:", error);
    }
  };

  // console.log("selectedImage", selectedImage);

  let profile_pic =
    userdata && userdata.profile_pic
      ? `${COMMON_API_BASE_URL}/${userdata.profile_pic.file_path.md}`
      : userdata && userdata.social_profile_pic;

  return (
    <Fragment>
      <Card
        sx={{
          position: "relative",
        }}
      >
        <CoverPicWrapper>
          <img
            width="100%"
            height="100%"
            alt="Team Member"
            src="/static/background/user-cover-pic.png"
            style={{
              objectFit: "cover",
            }}
          />
        </CoverPicWrapper>

        <ContentWrapper>
          <FlexBox justifyContent="center">
            <AvatarBadge
              badgeContent={
                <label htmlFor="icon-button-file">
                  {/* <input
                    type="file"
                    accept="image/*"
                    id="icon-button-file"
                    style={{
                      display: "none",
                    }}
                  /> */}
                  <input
                    type="file"
                    id="icon-button-file"
                    accept="image/*"
                    style={{
                      display: "none",
                    }}
                    onChange={handleImageChange}
                  />

                  <IconButton aria-label="upload picture" component="span">
                    <CameraAlt
                      sx={{
                        fontSize: 16,
                        color: "background.paper",
                      }}
                    />
                  </IconButton>
                </label>
              }
            >
              {userdata && (
                <AppAvatar
                  alt="Team Member"
                  src={`${
                    selectedImage
                      ? `${COMMON_API_BASE_URL}/${selectedImage}`
                      : profile_pic
                      ? profile_pic
                      : "/static/avatar/001-man.svg"
                    // "/static/avatar/001-man.svg"
                  }`}
                  sx={{
                    width: 100,
                    height: 100,
                  }}
                />
              )}
            </AvatarBadge>
          </FlexBox>

          <Box mt={2}>
            <div
              fontWeight={600}
              textAlign="center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4>
                {/* {!userdata?.first_name && !userdata?.last_name && "NO NAME"} */}
                {/* {userdata && userdata.first_name && userdata.first_name}{" "}
                {userdata && userdata.last_name && userdata.last_name} */}
                {userdata && userdata.name}
              </h4>
              <div
                style={{
                  marginLeft: "5px",
                }}
              >
                {userdata?.country && (
                  <CountryFlag code={userdata?.country} width={"20px"} />
                )}
              </div>
            </div>

            <StyledFlexBetween
              paddingTop={1}
              maxWidth={440}
              alignItems={"center"}
            >
              {/* {userdata?.paxfullAccounts &&
                userdata?.paxfullAccounts.map((items) => {
                  return (
                    <>
                      <div>
                        <Stack direction="row">
                          <img
                            style={{
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                            }}
                            src={items.paxful_avatar}
                            alt="userimage"
                          />

                          <FlexBox
                            alignItems={"center"}
                            sx={{
                              alignItems: "center",
                              marginLeft: "10px !important",
                            }}
                          >
                            <Tiny fontSize={12} fontWeight={500}>
                              <a
                                style={{
                                  marginLeft: "3px",
                                }}
                                href={
                                  "https://paxful.com/user/" +
                                  items.paxful_username
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {items.paxful_username}
                                <ArrowOutward
                                  sx={{
                                    fontSize: 14,
                                    marginLeft: "1px",
                                  }}
                                />
                              </a>
                            </Tiny>
                          </FlexBox>
                        </Stack>
                      </div>
                    </>
                  );
                })} */}

              <ListItem title={userdata?.role && "Vendor"} Icon={person} />
              <ListItem title={userdata?.country} Icon={MapMarkerIcon} />
              <ListItem
                title={<FormattedDate date={userdata?.createdAt} />}
                Icon={DateRange}
              />
              <ListItem
                title={
                  userdata && <OnlineStatus member={userdata} is_live={false} />
                }
                Icon={clock}
              />
            </StyledFlexBetween>
          </Box>

          {/* <StyledFlexBetween paddingTop={4} maxWidth={400}>
            <BoxItem amount={4550} title="Earnings" color="primary.main" />
            <BoxItem amount={60} title="Projects" color="info.main" />
            <BoxItem amount={2800} title="Success Rate" color="success.main" />
          </StyledFlexBetween> */}
        </ContentWrapper>

        {/* <StyledTabList variant="scrollable" onChange={handleTabList}>
          <Tab disableRipple label="Overview" value="1" />
          <Tab disableRipple label="Wallet" value="14" />
          <Tab disableRipple label="Billing" value="16" />
          <Tab disableRipple label="Atlantis Wallet" value="12" />
          <Tab disableRipple label="Atlantis Transactions" value="13" />
          <Tab disableRipple label="Trades" value="2" />
          <Tab disableRipple label="Transactions" value="3" />
          <Tab disableRipple label="Offers" value="4" />

          <Tab disableRipple label="Webhooks" value="7" />

          <Tab disableRipple label="Hiring Request" value="10" />
          <Tab disableRipple label="Notification" value="15" />
        </StyledTabList> */}
        <Tabs
          value={tabValue}
          onChange={handleTabList}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab disableRipple label="Overview" value="1" />
          <Tab disableRipple label="Trades" value="2" />
          <Tab disableRipple label="Venva Wallet" value="12" />
          <Tab disableRipple label="Venva Transactions" value="13" />
          <Tab disableRipple label="Trading Volume Transaction" value="18" />
          <Tab disableRipple label="Automations" value="19" />

          <Tab disableRipple label="P2P Offers" value="4" />

          <Tab disableRipple label="P2P Wallet" value="14" />
          {/* <Tab disableRipple label="Billing" value="16" /> */}
          <Tab disableRipple label="P2P Transactions" value="3" />
          <Tab disableRipple label="Hiring Request" value="10" />
          <Tab disableRipple label="Whitelisted Address" value="17" />
          <Tab disableRipple label="Venva Notifications" value="15" />

          {/* <Tab disableRipple label="Virtual Assistants" value="5" /> */}
          {/* <Tab disableRipple label="Processor Integration" value="6" /> */}
          <Tab disableRipple label="Webhooks" value="7" />
          {/* <Tab disableRipple label="Activity Log" value="8" /> */}
          {/* <Tab disableRipple label="Api Log" value="9" /> */}
          {/* <Tab disableRipple label="Atlantis Transactions" value="11" /> */}
        </Tabs>
      </Card>

      {children || <Outlet />}
    </Fragment>
  );
};

export default Layout; // ----------------------------------------------------------------

// ----------------------------------------------------------------
function ListItem({ title, Icon }) {
  return (
    <FlexBox alignItems="center">
      <Icon
        sx={{
          fontSize: 14,
          color: "text.secondary",
          mr: 1,
        }}
      />
      <Tiny fontWeight={500} color="text.secondary">
        {title}
      </Tiny>
    </FlexBox>
  );
}

function BoxItem({ title, amount, color }) {
  return (
    <Stack
      spacing={0.5}
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "divider",
        width: {
          sm: 120,
          xs: "100%",
        },
        marginBottom: {
          sm: 0,
          xs: 1,
        },
      }}
    >
      <H5 color={color}>${numeral(amount).format("0,00")}</H5>
      <Tiny fontWeight={500}>{title}</Tiny>
    </Stack>
  );
}
