import React, { useContext, useRef } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import _, { methods } from "underscore";
import auto_suggestions from "../../../../data/auto_suggestions";
import { useEffect } from "react";
import Autosuggest from "react-autosuggest";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import EmojiPicker from "emoji-picker-react";
import platforms from "data/platforms";
import Platform from "components/shared/Platform";
import emitter from "helpers/emitter";
import { getFileExtension } from "utils/utils";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Input,
  Paper,
  Popover,
  Switch,
  TextField,
} from "@mui/material";
import SelectOffers from "./SelectOffers";
import { UserContext } from "page-sections/user-list/ViewUser";
import { Small, Span } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import OfferType from "components/shared/OfferType";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import UploadImage from "./UploadImage";
import styled from "@emotion/styled";
import { SettingsContext } from "contexts/settingsContext";
import CloseIcon from "@mui/icons-material/Close";
import CryptoImg from "components/shared/CryptoImg";

// work on upload imaghe component
function Textarea2({
  onChange,
  enabled,
  message,
  offers,
  onRemove,
  isEditing,
  selectedOffers,
  label,
  platform,
}) {
  const { userdata, allOffers, activeAccount } = useContext(UserContext);

  const [value, setValue] = useState({
    enabled,
    message,
    offers,
  });

  const messageRef = useRef(value);

  const { settings, saveSettings } = useContext(SettingsContext);

  const [suggestions, setSuggestions] = useState([]);
  const [showEmojis, setShowEmojis] = useState("");
  const [themMode, setThemMode] = useState("light");

  const [showMore, setShowMore] = useState(false);
  const toggleshowMore = () => {
    setShowMore(!showMore);
  };

  const handleEmojiClick = ({ emoji }, index) => {
    const _value = { ...value };
    let _messages = [..._value.message];
    let __message = { ..._messages[index] };
    _messages[index].text = `${__message.text} ${emoji}`;
    _value.message = _messages;
    setValue(_value);
    onChange(_value);
  };

  //  const [activeTab, setActiveTab] = useState();
  //   const changeActiveTab = (tab) => {
  //     setActiveTab(tab);
  //   };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const ordered = reorder(
      message,
      result.source.index,
      result.destination.index
    );
    let _value = { ...value };
    _value.message = [...ordered];

    setValue(_value);

    onChange(_value);
  };
  useEffect(() => {
    setValue({
      enabled,
      message,
      offers,
    });
  }, [enabled, message, offers]);

  // useEffect(() => {
  //   const _theme = document.querySelector("html").getAttribute("data-theme"); //current theme
  //   //listner for theme change event
  //   setThemMode(_theme);

  //   const listner1 = emitter.addListener("theme:mode:changed", (theme) => {
  //     setThemMode(theme);
  //   });

  //   const listener = emitter.addListener("clicked:outside:emojis", () => {
  //     setShowEmojis(false);
  //   });

  //   const listner2 = emitter.addListener("clicked:outside:popover", () => {
  //     setShowMore(false);
  //   });

  //   return () => {
  //     listener.remove();
  //     listner1.remove();
  //     listner2.remove();
  //   };
  // }, []);

  const addInputs = (changed = true) => {
    let _value = { ...value };
    let _messages = [..._value.message];

    _messages.push({
      title: "",
      text: "",
      file: null,
      is_file: false,
    }); //add new field/value

    _value.message = _messages;
    setValue(_value);
    if (changed) {
      onChange(_value);
    }

    setTimeout(() => {
      //scrollElmentToBottom("#automated-messages-inputs-edit");
    }, 300);
  };

  const extension = value.file ? getFileExtension(value.file) : null;

  const RemoveInputs = (index) => {
    let _value = { ...value };
    let _messages = [..._value.message];

    //remove value
    _messages = [..._messages].filter((value, _index) => {
      return index != _index;
    });

    _value.message = _messages;
    setValue(_value);
    onChange(_value);
  };

  const timeoutId = useRef(null);

  const handleInputChange = (field, val, index, emitChange = false) => {
    let _value = { ...value };

    let _messages = [..._value.message];

    let __message = { ..._messages[index] };
    __message[field] = val;

    if (field === "is_file" && val !== null) {
      __message["text"] = "";
    }

    _messages[index] = __message;

    _value.message = _messages;
    setValue(_value);
    // setValue1(newValue);

    if (field === "file" && val !== null) {
      onChange(_value);
    }
  };

  const removeOffer = (index) => {
    const _value = { ...value };
    const offers = [..._value.offers].filter((offer, _index) => {
      return _index != index;
    });
    _value.offers = offers;
    setValue(_value);
    onChange(_value);
  };

  const getOfferDetails = (offer, index) => {
    const _offer = _.find(allOffers, function (_offer) {
      return (
        _offer.paxful_offer_hash == offer.value &&
        _offer.account._id == offer.account_id
      );
    });

    if (!_offer) return null;

    return (
      <>
        <Box
          sx={{
            alignItems: "center",
            padding: "5px",
          }}
          key={index}
          className={`mb-${
            index < value.length - 1 ? "1" : "0"
          } py-1 px-2 rounded border d-inline-block`}
        >
          <FlexBox
            sx={{
              backgroundColor: "#f5f8fa",
              borderRadius: "5px",
              padding: "5px",
              margin: "2px",
              alignItems: "start",
              justifyContent: "space-between",
            }}
            key={index}
            className={`mb-${
              index < value.length - 1 ? "1" : "0"
            } py-1 px-2 rounded border`}
          >
            <FlexBox>
              <Box mt={0.5}>
                {/* <PaxfulProfilePic size={20} account={_offer.account} /> */}
                <FlexBox
                  sx={{ alignItem: "center" }}
                  className="user-inner-wrap mt-1 mb-1"
                >
                  <Box
                    mx={0.5}
                    sx={{ alignItem: "center", position: "relative" }}
                  >
                    <PaxfulProfilePic
                      account={activeAccount}
                      size={20}
                      className={"ms-2"}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: -4,
                        left: 10,
                      }}
                    >
                      <Platform
                        platform={activeAccount?.platform}
                        size={15}
                        className={"w-20px me-1"}
                      />
                    </Box>
                  </Box>
                </FlexBox>
              </Box>
              <Box ml={1}>
                <FlexBox
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                  className="d-inline-flex align-items-center fw-bold"
                >
                  <Small color="black">
                    {_offer.offer.payment_method_name}
                  </Small>
                  <Box ml={0.5}>
                    {/* <Platform
                      size="20"
                      platform={_offer.account.platform}
                      className={"h-20px ms-2"}
                    /> */}
                    <CryptoImg
                      currency={_offer.offer.crypto_currency}
                      size={16}
                      className={"h-20px ms-2"}
                    />
                  </Box>
                </FlexBox>
                <FlexBox sx={{ alignItems: "center" }} className="">
                  <Small mr={1} fontSize={10} color="text.secondary">
                    {_offer.paxful_offer_hash}
                  </Small>

                  <Small
                    sx={{
                      marginRight: "3px",
                      borderRadius: "4px",
                      padding: "0rem .4rem",
                      backgroundColor:
                        _offer.offer.offer_type == "buy"
                          ? "success.main"
                          : "error.main",
                    }}
                  >
                    {_offer.offer.offer_type}
                  </Small>

                  <Small fontSize={10} color="text.secondary">
                    {_offer.offer.margin}%{" "}
                  </Small>
                </FlexBox>
              </Box>
            </FlexBox>

            <Small sx={{ cursor: "pointer" }}>
              <a
                className="cursor-pointer remove-offer"
                onClick={() => {
                  removeOffer(index);
                }}
              >
                <span className="svg-icon svg-icon-4">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x="7.05025"
                      y="15.5356"
                      width="12"
                      height="2"
                      rx="1"
                      transform="rotate(-45 7.05025 15.5356)"
                      fill="currentColor"
                    />
                    <rect
                      x="8.46447"
                      y="7.05029"
                      width="12"
                      height="2"
                      rx="1"
                      transform="rotate(45 8.46447 7.05029)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </a>
            </Small>
          </FlexBox>
        </Box>
      </>
    );
  };

  function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  const getSuggestions = (value) => {
    const inputValue = value ? value.trim().toLowerCase() : "";
    const inputLength = inputValue.length;

    return inputLength < 5
      ? auto_suggestions.messages
      : auto_suggestions.messages.filter(
          (message) =>
            message.text.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const getSuggestionValue = (suggestion) => {
    return suggestion;
  };

  const renderSuggestion = (suggestion) => {
    return (
      <>
        <div>
          <strong>{suggestion.title}</strong>
        </div>
        <div className="text-gray-800">{suggestion.text}</div>
      </>
    );
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleFileUpload = (file, index) => {
    handleInputChange("file", file, index);

    // setTimeout(() => {
    //   //clear text input
    //   handleInputChange("text", "", index);
    // }, 1000);
  };

  let initial_offers = value.offers;
  let more_offers = [];
  if (value.offers.length > 4) {
    initial_offers = value.offers.slice(0, 4);
    more_offers = value.offers.slice(4);
  }

  const [value1, setValue1] = React.useState("1");

  const handleChange = (e, newValue, index) => {
    setValue1(newValue);

    if (newValue == 1) {
      handleInputChange("is_file", false, index, false);
    } else if (newValue == 2) {
      handleInputChange("is_file", true, index, false);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // document.body.style.overflow = "hidden";
  };

  const handleClose = () => {
    setAnchorEl(null);
    // document.body.style.overflow = "visible";
  };

  const _open = Boolean(anchorEl);
  const _id = _open ? "simple-popover" : undefined;
  return (
    <>
      {allOffers && (
        <div>
          {isEditing && (
            // <div className="d-flex align-items-start justify-content-between">
            //   <div className="d-flex">
            //     {/* <Label className="align-items-start form-check form-switch form-check-custom form-check-solid red-green mb-3"> */}
            //     <Input
            //       className="form-check-input"
            //       type="checkbox"
            //       checked={value.enabled}
            //       onChange={({ target }) => {
            //         let _value = { ...value };
            //         _value.enabled = target.checked;
            //         setValue(_value);
            //         onChange(_value);
            //       }}
            //     />
            //     {/* </Label> */}
            //     <div className="ms-2">
            //       <div className="offer-enable-status">
            //         {value.enabled ? "Enabled" : "Disabled"}
            //       </div>
            //       {platform && (
            //         <small className="text-muted">
            //           Automated {label} is{" "}
            //           {value.enabled ? "enabled" : "disabled"} for following
            //           <Platform
            //             size={20}
            //             platform={platform}
            //             className={"h-20px ms-1 me-1"}
            //             outerClass=" d-inline-block"
            //           />
            //           {platforms[platform].title} offers
            //         </small>
            //       )}
            //     </div>
            //   </div>
            //   <a
            //     onClick={onRemove}
            //     className="cursor-pointer remove-card text-hover-danger"
            //   >
            //     <i className="fa-solid fa-xmark me-0"></i>
            //   </a>
            // </div>
            <FlexBox
              sx={{ alignItems: "center", justifyContent: "space-between" }}
              className="d-flex align-items-start justify-content-between"
            >
              <FlexBox className="d-flex">
                <Switch
                  checked={value.enabled}
                  onChange={({ target }) => {
                    let _value = { ...value };
                    _value.enabled = target.checked;
                    setValue(_value);
                    onChange(_value);
                  }}
                />
                {/* <Input
                className="form-check-input"
                type="checkbox"
                checked={value.enabled}
                onChange={({ target }) => {
                  let _value = { ...value };
                  _value.enabled = target.checked;
                  setValue(_value);
                  onChange(_value);
                }}
              /> */}
                <div className="ms-2">
                  <div className="offer-enable-status">
                    <Small fontSize={16}>
                      {value.enabled ? "Enabled" : "Disabled"}
                    </Small>
                  </div>

                  {platform && (
                    <FlexBox>
                      <Box
                        sx={{
                          display: "flex",
                          "@media (max-width: 1405px)": {
                            display: "block",
                          },
                        }}
                        fontSize={12}
                        color="text.secondary"
                      >
                        Automated {label} is{" "}
                        {value.enabled ? "enabled" : "disabled"} for following{" "}
                        {/* <FlexBox>
                          <span
                            sx={{
                              marginLeft: "8px",
                              marginRight: "5px",
                              "@media (max-width: 1405px)": {
                                marginLeft: 0,
                                marginRight: "5px",
                              },
                            }}
                          >
                            <Platform platform={platform} size={15} />
                          </span>
                          {platforms[platform].title} offers
                        </FlexBox> */}
                        <Span
                          mx={0.5}
                          sx={{
                            alignItem: "center",
                            position: "relative",
                            display: "inline",
                          }}
                        >
                          <PaxfulProfilePic
                            account={activeAccount}
                            size={20}
                            className={"ms-2"}
                          />
                          <Span
                            sx={{
                              position: "absolute",
                              top: -8,
                              left: 10,
                            }}
                          >
                            <Platform
                              platform={activeAccount?.platform}
                              size={15}
                              className={"w-20px me-1"}
                            />
                          </Span>
                        </Span>
                        {platforms[platform].title} offers
                      </Box>
                    </FlexBox>
                  )}
                </div>
              </FlexBox>
              <a
                onClick={onRemove}
                className="cursor-pointer remove-card text-hover-danger"
              >
                <Small
                  sx={{
                    backgroundColor: "#FFCCCB",
                    borderRadius: "5px",
                    padding: "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon color="error" sx={{ fontSize: 16 }} />
                </Small>
              </a>
            </FlexBox>
          )}

          <div className="mb-4">
            <div className="w-100">
              {!isEditing && activeAccount && (
                <SelectOffers
                  selected={selectedOffers}
                  onChange={(values) => {
                    let _value = { ...value };
                    _value.offers = values;
                    setValue(_value);
                    onChange(_value);
                  }}
                  isMulti={true}
                  hasOffers={value.offers.length > 0}
                  activeAccountId={activeAccount?._id}
                />
              )}

              {isEditing && (
                <div className="my-4 d--flex flex--wrap">
                  <div className="offer-tag--wrap">
                    {initial_offers?.map((offer, index) => {
                      <div className="offer-tag-wrap"></div>;
                      return (
                        <div
                          key={index}
                          className="offer-tag me-1 bg-light p-10px"
                        >
                          {getOfferDetails(offer, index)}
                        </div>
                      );
                    })}
                  </div>
                  <div className="offer-tag-wrap">
                    {_open && more_offers.length > 0 && (
                      <Popover
                        open={_open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        placement="top-start"
                        disablePortal={false}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {/* <Box className="p-2">
                          <div className="widget-scroll-y ">
                            {more_offers?.map((offer, index) => {
                              const _offer = _.find(
                                allOffers,
                                function (_offer) {
                                  return (
                                    _offer.paxful_offer_hash == offer.value &&
                                    _offer.account._id == offer.account_id
                                  );
                                }
                              );

                              return (
                                <div
                                  className={`d-flex justify-content-between mb-${
                                    index < more_offers.length - 1 ? "1" : "0"
                                  } py-1 px-2 rounded border`}
                                  key={index}
                                >
                                  <div className="d-flex">
                                    <span style={{ marginRight: "5px" }}>
                                      <PaxfulProfilePic
                                        size={20}
                                        account={_offer.account}
                                      />
                                    </span>
                                    <div>
                                      <div className="d-flex align-items-center text-gray-800">
                                        {_offer.offer.payment_method_name}
                                        <Platform
                                          size={20}
                                          className={"h-15px ms-2"}
                                          platform={_offer.account.platform}
                                        />
                                      </div>
                                      <div>
                                        <small className="text-muted">
                                          {_offer.paxful_offer_hash}
                                        </small>
                                        <OfferType
                                          value={_offer.offer.offer_type}
                                        />

                                        <small className="ms-2 text-muted">
                                          {_offer.offer.margin}%
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <a
                                      className="cursor-pointer remove-offer"
                                      onClick={() => {
                                        removeOffer(index);
                                      }}
                                    >
                                      <span className="svg-icon svg-icon-4">
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            opacity="0.5"
                                            x="7.05025"
                                            y="15.5356"
                                            width="12"
                                            height="2"
                                            rx="1"
                                            transform="rotate(-45 7.05025 15.5356)"
                                            fill="currentColor"
                                          />
                                          <rect
                                            x="8.46447"
                                            y="7.05029"
                                            width="12"
                                            height="2"
                                            rx="1"
                                            transform="rotate(45 8.46447 7.05029)"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Box> */}
                        <Box className="p-2">
                          <div className="widget-scroll-y ">
                            <Box
                              sx={{
                                padding: "5px",
                                // border: "1px solid lightgray",
                                // boxShadow:
                                //   "0 20px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              }}
                            >
                              <Box
                                sx={{ maxHeight: "40vh", minWidth: "300px" }}
                                className="widget-scroll-y "
                              >
                                {more_offers?.map((offer, index) => {
                                  const _offer = _.find(
                                    allOffers,
                                    function (_offer) {
                                      return (
                                        _offer.paxful_offer_hash ==
                                          offer.value &&
                                        _offer.account._id == offer.account_id
                                      );
                                    }
                                  );

                                  return (
                                    <FlexBox
                                      sx={{
                                        border: "1px solid lightgray",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        margin: "2px",
                                        alignItems: "start",
                                        justifyContent: "space-between",
                                      }}
                                      key={index}
                                      className={`mb-${
                                        index < value.length - 1 ? "1" : "0"
                                      } py-1 px-2 rounded border`}
                                    >
                                      <FlexBox>
                                        <Box mt={0.5}>
                                          <FlexBox
                                            sx={{ alignItem: "center" }}
                                            className="user-inner-wrap mt-1 mb-1"
                                          >
                                            <Box
                                              mx={0.5}
                                              sx={{
                                                alignItem: "center",
                                                position: "relative",
                                              }}
                                            >
                                              <PaxfulProfilePic
                                                account={activeAccount}
                                                size={20}
                                                className={"ms-2"}
                                              />
                                              <Box
                                                sx={{
                                                  position: "absolute",
                                                  top: -4,
                                                  left: 10,
                                                }}
                                              >
                                                <Platform
                                                  platform={
                                                    activeAccount?.platform
                                                  }
                                                  size={15}
                                                  className={"w-20px me-1"}
                                                />
                                              </Box>
                                            </Box>
                                          </FlexBox>
                                        </Box>
                                        <Box ml={1}>
                                          <FlexBox
                                            sx={{
                                              display: "inline-flex",
                                              alignItems: "center",
                                            }}
                                            className="d-inline-flex align-items-center fw-bold"
                                          >
                                            <Small
                                              color={
                                                settings.theme == "dark"
                                                  ? " white"
                                                  : "black"
                                              }
                                            >
                                              {_offer.offer.payment_method_name}
                                            </Small>
                                            {/* <Platform
                                              size="20"
                                              platform={_offer.account.platform}
                                              className={"h-20px ms-2"}
                                            /> */}
                                            <Box ml={0.5}>
                                              <CryptoImg
                                                currency={
                                                  _offer.offer.crypto_currency
                                                }
                                                size={16}
                                                className={"h-20px ms-2"}
                                              />
                                            </Box>
                                          </FlexBox>
                                          <FlexBox
                                            sx={{ alignItems: "center" }}
                                            className=""
                                          >
                                            <Small
                                              mr={1}
                                              fontSize={10}
                                              color="text.secondary"
                                            >
                                              {_offer.paxful_offer_hash}
                                            </Small>

                                            <Small
                                              sx={{
                                                marginRight: "3px",
                                                borderRadius: "4px",
                                                padding: "0rem .4rem",
                                                backgroundColor:
                                                  _offer.offer.offer_type ==
                                                  "buy"
                                                    ? "success.main"
                                                    : "error.main",
                                              }}
                                            >
                                              {_offer.offer.offer_type}
                                            </Small>

                                            <Small
                                              fontSize={10}
                                              color="text.secondary"
                                            >
                                              {_offer.offer.margin}%{" "}
                                            </Small>
                                          </FlexBox>
                                        </Box>
                                      </FlexBox>

                                      <Small sx={{ cursor: "default" }}>
                                        <a
                                          className="cursor-pointer remove-offer"
                                          // onClick={() => {
                                          //   removeOffer(index);
                                          // }}
                                        >
                                          <span className="svg-icon svg-icon-4">
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                opacity="0.5"
                                                x="7.05025"
                                                y="15.5356"
                                                width="12"
                                                height="2"
                                                rx="1"
                                                transform="rotate(-45 7.05025 15.5356)"
                                                fill="currentColor"
                                              />
                                              <rect
                                                x="8.46447"
                                                y="7.05029"
                                                width="12"
                                                height="2"
                                                rx="1"
                                                transform="rotate(45 8.46447 7.05029)"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </span>
                                        </a>
                                      </Small>
                                    </FlexBox>
                                  );
                                })}
                              </Box>
                            </Box>
                          </div>
                        </Box>
                      </Popover>
                    )}
                  </div>
                  {more_offers.length > 0 && (
                    <div
                      style={{
                        pointerEvents: "auto",
                      }}
                      className="w-100"
                    >
                      <a
                        onClick={handleClick}
                        id={"more-offers"}
                        className="open-popover cursor-pointer fw-bolder more-items-link fs-7"
                      >
                        {more_offers.length > 1 && (
                          <Small
                            sx={{
                              cursor: "pointer",
                            }}
                            // id={`more_offers_${id}`}
                          >
                            +{more_offers.length} more
                          </Small>
                        )}
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            className="input-text setting-input-container"
            id={
              !isEditing
                ? "automated-messages-inputs-add"
                : "automated-messages-inputs-edit"
            }
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-inputs" direction="vertical">
                {(provided, snapshot) => (
                  <div
                    className="d-flex flex-column"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {value.message.map((message, index) => {
                      const inputProps = {
                        placeholder: "Pick a suggestion or type your own...",
                        value: message.text,
                        onChange: (event, { newValue, method }) => {
                          if (method == "type") {
                            handleInputChange("text", newValue, index);
                          } else if (method == "click") {
                            let _value = JSON.parse(JSON.stringify(value));
                            _value.message[index] = { ...newValue };
                            setValue(_value);
                            // onChange(_value);
                            console.log("ONCHANGE CALLED");
                          }
                        },
                        onBlur: () => {
                          if (
                            JSON.stringify(
                              messageRef.current?.message[index]
                            ) !== JSON.stringify(value.message[index])
                          ) {
                            console.log("ONBLUR CALLED");
                            onChange(value);
                            messageRef.current = value;
                          }

                          // handleInputChange("text", message.text, index, true);
                        },
                      };
                      return (
                        <div key={index}>
                          <Draggable
                            key={index}
                            draggableId={`message-card-input-${index}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <div className="position-relative">
                                  <div>
                                    <span
                                      className="grip-icon"
                                      {...provided.dragHandleProps}
                                    >
                                      <i className="bi bi-grip-vertical"></i>
                                    </span>
                                    <div key={index} className="d-flex mb-2">
                                      {/* <TabContext value={value1}>
                                        <Box sx={{ width: "100%" }}>
                                          <Tabs
                                            value={value1}
                                            onChange={(e, newValue) =>
                                              handleChange(e, newValue, index)
                                            }
                                            textColor="secondary"
                                            indicatorColor="secondary"
                                            aria-label="secondary tabs example"
                                          >
                                            <Tab label="Text" value="1" />
                                            <Tab label="Image" value="2" />
                                          </Tabs>
                                        </Box>
                                        <TabPanel value="1">
                                          {!message.is_file && (
                                            <div className="w-100 position-relative has-input-title messages-input-box position-relative text-gray-800">
                                              <Input
                                                onClick={() => {
                                                  // console.log(textAreaRef.current);
                                                  // textAreaRef.current?.revealSuggestions();
                                                }}
                                                type="text"
                                                placeholder="Title"
                                                value={message.title}
                                                className="fw-bold input-title"
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    "title",
                                                    e.target.value,
                                                    index
                                                  )
                                                }
                                                onBlur={() => {
                                                  if (
                                                    JSON.stringify(
                                                      messageRef.current
                                                        ?.message[index]
                                                    ) !==
                                                    JSON.stringify(
                                                      value.message[index]
                                                    )
                                                  ) {
                                                    console.log(
                                                      "INPUT ONBLUR CALLED"
                                                    );
                                                    onChange(value);
                                                    messageRef.current = value;
                                                  }

                                                  // handleInputChange("text", message.text, index, true);
                                                }}
                                              ></Input>

                                              <Autosuggest
                                                suggestions={suggestions}
                                                onSuggestionsFetchRequested={
                                                  onSuggestionsFetchRequested
                                                }
                                                onSuggestionsClearRequested={
                                                  onSuggestionsClearRequested
                                                }
                                                getSuggestionValue={
                                                  getSuggestionValue
                                                }
                                                renderSuggestion={
                                                  renderSuggestion
                                                }
                                                inputProps={inputProps}
                                                //alwaysRenderSuggestions={true}
                                                shouldRenderSuggestions={(v) =>
                                                  v.trim().length > -1
                                                }
                                                renderInputComponent={(
                                                  inputProps
                                                ) => (
                                                  <div className="input-textarea">
                                                    <textarea
                                                      {...inputProps}
                                                    ></textarea>
                                                  </div>
                                                )}
                                              />

                                              <a
                                                onClick={() =>
                                                  setShowEmojis(index)
                                                }
                                                className={`open-emojis messaging-emoji position-absolute cursor-pointer ${
                                                  isEditing ? "is-editing" : ""
                                                }`}
                                                type="button"
                                              >
                                                <i className="fa-regular fa-face-smile fs-2"></i>
                                              </a>

                                              {showEmojis === index && (
                                                <div className="emoji-picker emoji-picker-textarea2 position-absolute end-3 ">
                                                  <EmojiPicker
                                                    skinTonesDisabled={true}
                                                    onEmojiClick={(e) =>
                                                      handleEmojiClick(e, index)
                                                    }
                                                    theme={themMode}
                                                    height={380}
                                                    previewConfig={{
                                                      showPreview: false,
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </TabPanel>
                                        <TabPanel value="2">
                                          {message.is_file && (
                                            <>
                                              <UploadImage
                                                titleValue={message.title}
                                                onTitleChange={(title) => {
                                                  handleInputChange(
                                                    "title",
                                                    title,
                                                    index
                                                  );
                                                }}
                                                file={message.file}
                                                onUpload={(file) =>
                                                  handleFileUpload(file, index)
                                                }
                                                onRemove={(file) => {
                                                  handleInputChange(
                                                    "file",
                                                    null,
                                                    index
                                                  );
                                                }}
                                              />
                                            </>
                                          )}
                                        </TabPanel>
                                      </TabContext> */}

                                      <Paper
                                        sx={{
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Tabs
                                          className="custom-tabs"
                                          value={
                                            value.message[index].is_file ? 2 : 1
                                          }
                                          onChange={(e, newValue) =>
                                            handleInputChange(
                                              "is_file",
                                              newValue === 2,
                                              index,
                                              false
                                            )
                                          }
                                          centered
                                        >
                                          <Tab label="Text" value={1} />
                                          <Tab label="Image" value={2} />
                                        </Tabs>

                                        {value.message[index].is_file ? (
                                          <UploadImage
                                            titleValue={
                                              value.message[index].title
                                            }
                                            onTitleChange={(title) =>
                                              handleInputChange(
                                                "title",
                                                title,
                                                index
                                              )
                                            }
                                            file={value.message[index].file}
                                            onUpload={(file) =>
                                              handleFileUpload(file, index)
                                            }
                                            onRemove={() =>
                                              handleInputChange(
                                                "file",
                                                null,
                                                index
                                              )
                                            }
                                          />
                                        ) : (
                                          <Box
                                            mt={1}
                                            sx={{
                                              backgroundColor: "#f5f8fa",
                                              borderRadius: "10px",
                                            }}
                                            className="w-100 position-relative has-input-title messages-input-box position-relative text-gray-800"
                                          >
                                            <Box
                                              sx={{
                                                paddingLeft: "1.75rem",
                                                paddingTop: "0.75rem",
                                              }}
                                            >
                                              <TextField
                                                InputProps={{
                                                  disableUnderline: true,
                                                  style: {
                                                    color: "gray",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  },
                                                }}
                                                id="standard-basic"
                                                variant="standard"
                                                type="text"
                                                value={message.title}
                                                placeholder="Title"
                                                className="fw-bold input-title"
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    "title",
                                                    e.target.value,
                                                    index
                                                  )
                                                }
                                                onBlur={() => {
                                                  if (
                                                    JSON.stringify(
                                                      messageRef.current
                                                        ?.message[index]
                                                    ) !==
                                                    JSON.stringify(
                                                      value.message[index]
                                                    )
                                                  ) {
                                                    console.log(
                                                      "INPUT ONBLUR CALLED"
                                                    );
                                                    onChange(value);
                                                    messageRef.current = value;
                                                  }

                                                  // handleInputChange("text", message.text, index, true);
                                                }}
                                              ></TextField>
                                            </Box>

                                            <Autosuggest
                                              suggestions={suggestions}
                                              onSuggestionsFetchRequested={
                                                onSuggestionsFetchRequested
                                              }
                                              onSuggestionsClearRequested={
                                                onSuggestionsClearRequested
                                              }
                                              getSuggestionValue={
                                                getSuggestionValue
                                              }
                                              renderSuggestion={
                                                renderSuggestion
                                              }
                                              inputProps={inputProps}
                                              //alwaysRenderSuggestions={true}
                                              shouldRenderSuggestions={(v) =>
                                                v.trim().length > -1
                                              }
                                              renderInputComponent={(
                                                inputProps
                                              ) => (
                                                <div className="input-textarea">
                                                  <textarea
                                                    {...inputProps}
                                                  ></textarea>
                                                </div>
                                              )}
                                            />
                                            <Box
                                              sx={{
                                                position: "relative",
                                                textAlign: "right",
                                              }}
                                            >
                                              <a
                                                onClick={() =>
                                                  setShowEmojis(index)
                                                }
                                                className={`open-emojis messaging-emoji position-absolute cursor-pointer ${
                                                  isEditing ? "is-editing" : ""
                                                }`}
                                                type="button"
                                              >
                                                <i className="fa-regular fa-face-smile fs-2"></i>
                                              </a>

                                              {showEmojis === index && (
                                                <div className="emoji-picker emoji-picker-textarea2 position-absolute end-3 ">
                                                  <EmojiPicker
                                                    skinTonesDisabled={true}
                                                    onEmojiClick={(e) =>
                                                      handleEmojiClick(e, index)
                                                    }
                                                    theme={themMode}
                                                    height={380}
                                                    previewConfig={{
                                                      showPreview: false,
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </Box>
                                          </Box>
                                        )}
                                      </Paper>
                                      {/* remove button */}
                                      {/* <Box
                                        sx={{
                                          position: "relative",
                                          textAlign: "right",
                                        }}
                                      >
                                        {value.message.length > 1 && (
                                          <button
                                            size="sm"
                                            className={`ms-2 btn-remove-message position--absolute ${
                                              value.message.length === 1
                                                ? " disbaled"
                                                : " text-hover-gray-500 cursor-pointer"
                                            }`}
                                            // onClick={() => RemoveInputs(index)}
                                          >
                                            <i className="fa-solid fa-circle-xmark text-gray-400"></i>
                                          </button>
                                        )}
                                      </Box> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <FlexBox
            sx={{
              justifyContent: "center",
            }}
            className="d-flex flex-center"
          >
            <Small
              mt={10}
              sx={{
                padding: "5px 10px",
                backgroundColor: "lightgray",
                borderRadius: "5px",
                color: "gray",
                cursor: "pointer",
              }}
              variant="contained"
              // color="gray"
              disabled={
                message[0].is_file
                  ? message[0].file == null
                  : message[0].text == ""
              }
              className="cursor-pointer btn btn-light btn-active-light-primary btn-sm mt-2"
              onClick={() => addInputs(false)}
            >
              + Add More
            </Small>
          </FlexBox>
        </div>
      )}
    </>
  );
}

Textarea2.defaultProps = {
  isEditing: false,
  enabled: true,
  message: "",
  payment_methods: [],
  uploadImage: false,
};

// const mapStateToProps = ({ offers }) => {
//   return {
//     allOffers: offers.offers,
//   };
// };

export default Textarea2;
