import { Box, Button, Card, Divider, IconButton } from "@mui/material";
import { H2, H4, H6, Paragraph, Small } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import BackButton from "page-sections/profiles/profile/Trades/BackButton";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useNavigate } from "react-router-dom";
import { FormattedDate } from "components/shared/FormattedDate";
import UserSymbol from "components/shared/UserSymbol";
import { COMMON_API_BASE_URL } from "config";
import { capitalizeFLetter } from "utils/utils";
import CryptoImg from "components/shared/CryptoImg";
import { FslightGalleryForSingleImage } from "page-sections/profiles/profile/Trades/FsligthGallery";

function Offers({ userRequest }) {
  const navigate = useNavigate();
  const [toggler, setToggler] = useState(false);

  const [first, setfirst] = useState(null);

  // Use state to manage whether to show all items or not
  const [showAll, setShowAll] = useState(false);

  // Function to toggle the display of all items
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  let initialValue = showAll
    ? userRequest.offers
    : userRequest.offers.slice(0, 4);

  return (
    <>
      <Card
        sx={{
          minHeight: 700,
          height: "100%",
          pb: 1,
        }}
      >
        <Box>
          <FlexBox p={3} alignItems="center">
            <BackButton onClick={() => navigate("/dashboard/user-request")} />
            {/* <ArrowBack /> */}
            <H4>{capitalizeFLetter(userRequest.details.type)}</H4>
          </FlexBox>
        </Box>
        <Divider />
        <Box pt={3} pl={4}>
          <H6>Offers ({userRequest.offers.length}) </H6>
        </Box>

        <FlexBox sx={{ flexWrap: "wrap" }} px={3} pt={2}>
          {userRequest &&
            initialValue.map((_offer) => {
              console.log("offer", _offer);
              return (
                <>
                  <FlexBox
                    m={1}
                    p={1}
                    sx={{
                      backgroundColor: "grey.100",
                      justifyContent: "center",
                      borderRadius: "9px",
                    }}
                  >
                    {/* <PaxfulProfilePic size={20} account={_offer.account} /> */}
                    <FlexBox
                      sx={{ alignItem: "center" }}
                      className="user-inner-wrap mt-1 mb-1"
                    >
                      <Box
                        mx={0.5}
                        sx={{ alignItem: "center", position: "relative" }}
                      >
                        <PaxfulProfilePic
                          account={_offer?.account}
                          size={20}
                          className={"ms-2"}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            top: -4,
                            left: 10,
                          }}
                        >
                          <Platform
                            platform={_offer.account?.platform}
                            size={15}
                            className={"w-20px me-1"}
                          />
                        </Box>
                      </Box>
                    </FlexBox>
                    <Box ml={1}>
                      <FlexBox>
                        {" "}
                        <Small>{_offer.offer.payment_method_name}</Small>
                        <Box ml={1}>
                          {" "}
                          {/* <Platform
                            size={20}
                            platform={_offer?.account?.platform}
                          /> */}
                          <CryptoImg
                            currency={_offer.offer.crypto_currency}
                            size={16}
                            className={"h-20px ms-2"}
                          />
                        </Box>
                      </FlexBox>
                      <FlexBox>
                        <Small color="text.secondary" className="text-muted">
                          {_offer.paxful_offer_hash}
                        </Small>
                        <Small
                          sx={{
                            marginLeft: "8px",
                            borderRadius: "4px",
                            padding: ".1rem .5rem",
                            backgroundColor:
                              _offer.offer.offer_type == "buy"
                                ? "success.main"
                                : "error.main",
                          }}
                        >
                          {_offer.offer.offer_type}
                        </Small>
                        <Small
                          color="text.secondary"
                          sx={{
                            marginLeft: "8px",
                          }}
                        >
                          {_offer.offer.margin}%
                        </Small>
                      </FlexBox>
                    </Box>
                  </FlexBox>
                </>
              );
            })}
        </FlexBox>
        {/* <FlexBox px={3} sx={{ justifyContent: "end" }}>
          <IconButton onClick={toggleShowAll} color={"primary"}>
            {<AddIcon />}
            {<HorizontalRuleIcon />}
          </IconButton>
        </FlexBox> */}
        {userRequest.offers.length > 4 && (
          <FlexBox px={3} sx={{ justifyContent: "end" }}>
            <IconButton onClick={toggleShowAll} color={"primary"}>
              {showAll ? <HorizontalRuleIcon /> : <AddIcon />}
            </IconButton>
          </FlexBox>
        )}
        <Box pl={4}>
          <H6>Comments</H6>
          <Paragraph mt={1} color="text.secondary">
            {userRequest.details.comment}
          </Paragraph>
        </Box>
        <Box pl={4} pt={3}>
          <H6 mb={1}>Details</H6>
          {/* <FlexBox mt={1} sx={{ alignItems: "center" }}>
            <Paragraph color="text.secondary">Submitted By :</Paragraph>
            <FlexBox
              ml={2}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <UserSymbol user={userRequest.submittedBy} size={20} />
              <Box
                sx={{
                  marginLeft: "10px !important",
                }}
              >
                <Paragraph
                  fontSize={14}
                  fontWeight={600}
                  color="text.secondary"
                >
                  {userRequest?.submittedBy?.first_name}{" "}
                  {userRequest?.submittedBy?.last_name}
                </Paragraph>
              </Box>
            </FlexBox>
          </FlexBox> */}
          <FlexBox className="d-flex">
            <Small
              fontSize={14}
              width={"125px"}
              className="w-125px text-gray-600"
            >
              Submitted By :
            </Small>
            <FlexBox sx={{ justifyContent: "center", alignItems: "center" }}>
              <UserSymbol
                user={userRequest.submittedBy}
                size={20}
                showOnline={true}
                isBottomZero={0}
              />
              <Box
                sx={{
                  marginLeft: "10px !important",
                }}
              >
                <Paragraph
                  fontSize={14}
                  fontWeight={600}
                  color="text.secondary"
                >
                  {userRequest?.submittedBy?.first_name}{" "}
                  {userRequest?.submittedBy?.last_name}
                </Paragraph>
              </Box>
            </FlexBox>
          </FlexBox>

          {userRequest?.clearAt && (
            <FlexBox className="d-flex">
              <Small
                fontSize={14}
                width={"125px"}
                className="w-125px text-gray-600"
              >
                Clear At :
              </Small>
              <Small
                fontSize={14}
                className="fw-semibold"
                fontWeight={600}
                color="text.secondary"
              >
                <FormattedDate date={userRequest?.clearAt} />
              </Small>
            </FlexBox>
          )}
          {userRequest?.clearBy && (
            <FlexBox className="d-flex">
              <Small
                fontSize={14}
                width={"125px"}
                className="w-125px text-gray-600"
              >
                Submitted By :
              </Small>
              <FlexBox sx={{ justifyContent: "center", alignItems: "center" }}>
                <UserSymbol
                  user={userRequest?.clearBy}
                  size={20}
                  showOnline={true}
                  isBottomZero={0}
                />
                <Box
                  sx={{
                    marginLeft: "10px !important",
                  }}
                >
                  <Paragraph
                    fontSize={14}
                    fontWeight={600}
                    color="text.secondary"
                  >
                    {userRequest?.clearBy?.first_name}{" "}
                    {userRequest?.clearBy?.last_name}
                  </Paragraph>
                </Box>
              </FlexBox>
            </FlexBox>
          )}
          {userRequest?.file && (
            <FlexBox sx={{ alignItems: "center" }}>
              <Small
                fontSize={14}
                width={"125px"}
                className="w-125px text-gray-600"
              >
                Image :
              </Small>
              <a onClick={() => setToggler(!toggler)}>
                <img
                  style={{
                    width: "30px",
                    marginTop: "0.4rem",
                  }}
                  src={`${COMMON_API_BASE_URL}/${userRequest?.file.file_path}`}
                />
              </a>

              <FslightGalleryForSingleImage
                image={`${COMMON_API_BASE_URL}/${userRequest?.file.file_path}`}
                toggler={toggler}
              />
            </FlexBox>
          )}
        </Box>
      </Card>
    </>
  );
}

export default Offers;
