import {
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  styled,
} from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward, ContentCopy } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import { COMMON_API_BASE_URL } from "../../config";
import FlexBox from "components/flexbox/FlexBox";
import { formatCurrency, getCountryNameByCode } from "utils/currencies";
import Countdown from "react-countdown";
import moment from "moment";
import OfferType from "components/shared/OfferType";
import Platform from "components/shared/Platform";
import React, { useState } from "react";
import CountryFlag from "components/shared/CountryFlag";
import Timezone from "components/shared/Timezone";
import VASymbol from "components/shared/VASymbol";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import CustomCountdown from "components/shared/CustomCountdown";
import BadgeMui from "components/shared/BadgeMui";
import { secondary } from "theme/colors";
import notification from "helpers/notifications";
import ATooltip from "components/shared/ATooltip";
import CreatedBy from "components/shared/CreatedBy";

const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;

export const StatusBadge = ({ color, title }) => {
  return (
    <Small
      sx={{
        width: 100,
        fontSize: 10,
        color: "#fff",
        borderRadius: "4px",
        textAlign: "center",
        padding: ".2rem 1rem",
        backgroundColor: color,
        // marginLeft: "0.5rem",
      }}
    >
      {title}
    </Small>
  );
};

// const StyledButton = styled(Button)(() => ({
//   padding: 4,
//   minWidth: 60,
//   marginRight: 3,
// }));

const Image = styled("img")`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

const countDownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (!completed) {
    return (
      <Paragraph fontSize={10} fontWeight="bold">
        {days > 0 ? `${days.toString().padStart(2, "0")}d` : ""}{" "}
        {hours > 0 ? `${hours.toString().padStart(2, "0")}h` : ""}{" "}
        {minutes > 0 ? `${minutes.toString().padStart(2, "0")}m` : ""}{" "}
        {seconds.toString().padStart(2, "0")}s
      </Paragraph>
    );
  } else {
    return <></>;
  }
};

export function VASchedule({ schedule }) {
  return (
    <>
      {schedule?.is_active && schedule.shift_ends_in && (
        <span>
          <BadgeMui color={"primary.main"}>Active</BadgeMui>
          <Paragraph mt={1} fontSize={11} color={"gray"}>
            Shift ends in
          </Paragraph>
          <Box
            sx={{
              color: "secondary",
            }}
            className="va-schedule-timer text-gray-800"
          >
            {/* <CustomCountdown
              targetDate={moment()
                .add(schedule.shift_ends_in / 1000, "seconds")
                .toDate()}
            /> */}
            <CustomCountdown
              targetDate={moment(schedule.shift_ends_in * 1000).toDate()}
            />
          </Box>
        </span>
      )}
      {!schedule?.is_active && schedule?.shiftStarts && (
        <span>
          <BadgeMui color={"primary.main"}>Scheduled</BadgeMui>
          <Paragraph mt={1} fontSize={11} color={"gray"}>
            Scheduled to work in
          </Paragraph>

          <Box
            sx={{
              color: "gray",
            }}
            className="va-schedule-timer text-gray-800"
          >
            <CustomCountdown
              targetDate={moment(schedule.shiftStarts * 1000).toDate()}
            />
          </Box>
        </span>
      )}
      {schedule == undefined && (
        <Small
          sx={{
            width: 100,
            fontSize: 10,
            color: "#fff",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor: "success.main",
          }}
        >
          Completed
        </Small>
      )}
    </>
  );
}
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};

const InvoiceColumnShape = (assignedUser) => {
  return [
    {
      Header: "ID",
      accessor: "_id",
      Cell: ({ value }) => (
        <>
          <Box
            sx={{
              maxWidth: "40px",
              marginRight: "10px",
            }}
          >
            <ATooltip content={value}>
              <Span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  copyTextToClipboard(value);
                }}
              >
                {value.slice(0, 2)}...
                {value.slice(value.length - 3, value.length)}
              </Span>
            </ATooltip>
          </Box>
        </>
      ),
    },
    {
      Header: () => <Box sx={{ marginLeft: "10px" }}>DATE </Box>,
      accessor: "createdAt",
      Cell: ({ value }) => (
        <Box>
          <Paragraph fontSize={13} color="text.secondary">
            <FormattedDateTime date={value} />
          </Paragraph>
          <Small
            sx={{
              width: 100,
              fontSize: 10,
              color: "#fff",
              borderRadius: "4px",
              textAlign: "center",
              padding: ".2rem 1rem",
              backgroundColor: "lightgrey",
              color: "black",
            }}
          >
            <FromNow date={value} />
          </Small>
        </Box>
      ),
    },
    {
      Header: () => <Box sx={{ marginLeft: "12px" }}>VENDOR </Box>,
      accessor: "user",
      Cell: ({ row }) => {
        const user = row.original.user;
        return (
          <FlexBox sx={{ alignItems: "center" }}>
            <span>
              <UserSymbol
                user={user}
                size={30}
                showOnline={true}
                isBottomZero={0}
              />
            </span>
            <Box sx={{ marginLeft: "10px !important" }}>
              <Paragraph fontSize={13} color="text.secondary">
                <Link to={`/dashboard/users/${user._id}`}>
                  {user.first_name} {user.last_name}
                </Link>
              </Paragraph>
            </Box>
          </FlexBox>
        );
      },
    },

    {
      Header: () => <Box sx={{ marginLeft: "10px" }}> FAKE VA </Box>,
      accessor: "assignedVA.name",
      Cell: ({ row }) => {
        const { profile_pic, name, country, timezone, first_name, last_name } =
          row?.original?.assignedVA;
        return (
          <>
            <Box>
              {row.original?.assignedVA && (
                <>
                  <FlexBox alignItems={"center"}>
                    <VASymbol user={row?.original?.assignedVA} size={30} />
                    <Box ml={1}>
                      <FlexBox sx={{ alignItems: "center" }}>
                        <Paragraph fontSize={13} color="text.secondary">
                          {first_name + " " + last_name}
                        </Paragraph>
                        <Box ml={1}>
                          <CountryFlag
                            width={"20px"}
                            code={country}
                            content={getCountryNameByCode(country)}
                          />
                        </Box>
                      </FlexBox>
                      <FlexBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <small className="me-1">
                          Only has access to trading tool
                        </small>
                        <span
                          style={{ marginLeft: "5px" }}
                          className="svg-icon svg-icon-success svg-icon-2"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.3"
                              x="2"
                              y="2"
                              width="20"
                              height="20"
                              rx="5"
                              fill="green"
                            />
                            <path
                              d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                              fill="green"
                            />
                          </svg>
                        </span>
                      </FlexBox>
                    </Box>
                  </FlexBox>
                  <Box>
                    <Box ml={4}>
                      {/* <Timezone
                        timezone={timezone}
                        color={"light"}
                        className={"text-gray-800"}
                      /> */}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </>
        );
      },
    },
    {
      Header: () => <Box sx={{ marginLeft: "10px" }}>ASSIGN VA </Box>,
      accessor: "assignedUser",
      Cell: ({ row }) => {
        // let { email, first_name, last_name, country, mobile_number } =
        //   row.original?.assignedUser;
        // if (!email) {
        //   email = mobile_number;
        // }
        return (
          <>
            {row.original?.assignedUser ? (
              <FlexBox>
                <Box mt={1}>
                  <UserSymbol user={row.original?.assignedUser} size={30} />
                </Box>
                <Box
                  ml={1}
                  sx={{
                    maxWidth: "190px",
                    overflowWrap: "break-word",
                  }}
                >
                  <FlexBox sx={{ alignItems: "center" }}>
                    <Paragraph
                      fontSize={13}
                      color="text.secondary"
                      fontWeight={"bold"}
                    >
                      {" "}
                      {row.original?.assignedUser.first_name +
                        " " +
                        row.original?.assignedUser.last_name}
                    </Paragraph>
                    <Span ml={1}>
                      <CountryFlag
                        code={row.original?.assignedUser.country}
                        width={"20px"}
                      />
                    </Span>
                  </FlexBox>

                  <Paragraph fontSize={13} color="text.secondary">
                    {row.original?.assignedUser.email ??
                      row.original?.assignedUser.mobile_number}
                  </Paragraph>
                </Box>
              </FlexBox>
            ) : row.original.vaSchedule ? (
              <Span
                onClick={() => assignedUser(row.original)}
                color="primary.main"
              >
                Assign VA
              </Span>
            ) : null}
          </>
        );
      },
    },

    {
      Header: () => <Box sx={{ marginLeft: "10px" }}>FEE </Box>,
      accessor: "row.original",
      Cell: ({ row }) => {
        const { fee, hours, rate } = row.original;
        return (
          <>
            <Paragraph fontSize={13} color="text.secondary">
              {formatCurrency(fee)}
            </Paragraph>
            <Small color="text.secondary">
              {hours} {hours > 1 ? "hours" : "hour"} @ {formatCurrency(rate)} /
              hr
            </Small>
          </>
        );
      },
    },
    {
      Header: () => <Box sx={{ marginLeft: "10px" }}>STATUS </Box>,
      accessor: "vaSchedule",
      Cell: ({ row }) => {
        return (
          <>
            {row.original.vaSchedule ? (
              <VASchedule schedule={row.original.vaSchedule} />
            ) : (
              // <Badge color="success">Completed</Badge>
              <Small
                sx={{
                  width: 100,
                  fontSize: 10,
                  color: "#fff",
                  borderRadius: "4px",
                  textAlign: "center",
                  padding: ".2rem 1rem",
                  backgroundColor: "success.main",
                }}
              >
                Completed
              </Small>
            )}

            {row.original.createdBy && (
              <Box mt={1}>
                <CreatedBy user={row.original.createdBy} otherUser />
              </Box>
            )}
          </>
        );
      },
    },
    {
      Header: "ACTIONS",
      accessor: "sad",
      Cell: ({ row }) => {
        const { _id } = row.original;

        return (
          <>
            <StyledButton
              component={Link}
              to={`/dashboard/hire-requests/${_id}`}
              variant="outlined"
              color="primary"
              size="small"
            >
              View
            </StyledButton>
          </>
        );
      },
    },
  ];
};
export default InvoiceColumnShape;
