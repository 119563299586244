import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import CryptoImg from "components/shared/CryptoImg";
import { FormattedDateTime } from "components/shared/FormattedDate";
import PaxfulAccount from "components/shared/PaxfulAccount";
import UserSymbol from "components/shared/UserSymbol";
import { H6, Paragraph, Small, Span, Tiny } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import { copyTextToClipboard } from "utils/utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import cryptoAssets from "data/supportedAssets";

const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;

const AddressColumnShape = (onCellClick) => [
  {
    Header: " DATE",
    accessor: "createdAt",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {<FormattedDateTime date={value} />}
      </Paragraph>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "8px" }}>CRYPTO</Box>,
    accessor: "crypto_currency_code",
    Cell: ({ value }) => {
      const { code } = cryptoAssets.find(({ assetID }) => assetID === value);
      console.log("crypto_currency_code", code);
      return (
        <Paragraph sx={{ minWidth: "100px" }}>
          <CryptoImg currency={code} size={20} />
        </Paragraph>
      );
    },
    // <CryptoImg currency={value} size={20} />,
  },
  {
    Header: () => <Box sx={{ marginLeft: "11px" }}>ACCOUNT</Box>,
    accessor: "account",
    Cell: ({ value }) => {
      return (
        <>
          {value ? (
            <PaxfulAccount item={value} size={25} />
          ) : (
            <FlexBox>
              <img
                alt="Logo"
                src="/media/logos/logo-minimize.svg"
                style={{
                  height: "20px",
                }}
                class="h-20px"
              />

              <Small ml={1} color="text.secondary" fontSize={12}>
                Venva Wallet
              </Small>
            </FlexBox>
          )}
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>ADDRESS</Box>,
    accessor: "address",
    Cell: ({ row }) => {
      const { address, crypto_currency_code, title } = row.original;
      const { code } = cryptoAssets.find(
        ({ assetID }) => assetID === crypto_currency_code
      );

      return (
        <Box>
          <FlexBox>
            <CryptoImg currency={code} size={20} />
            <Small ml={1} color={"text.secondary"}>
              {title}
            </Small>
          </FlexBox>
          <FlexBox
            sx={{
              maxWidth: "300px",
              wordBreak: "break-word",
            }}
          >
            <Span fontSize={13} color="text.secondary">
              {address}
            </Span>
            {address && (
              <Span ml={1}>
                <ContentCopyIcon
                  sx={{
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                  color={"primary"}
                  onClick={() => copyTextToClipboard(address)}
                />
              </Span>
            )}
          </FlexBox>
        </Box>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>ACTIVE</Box>,
    accessor: "isActive",
    Cell: ({ value }) => (
      <Small
        sx={{
          width: 100,
          fontSize: 10,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: ".2rem 1rem",
          backgroundColor: value ? "success.main" : "error.main",
        }}
      >
        {value ? "Active" : "Not Active"}
      </Small>
    ),
  },
  {
    Header: "ACTION",
    accessor: "action",
    Cell: ({ row }) => {
      const { _id } = row.original;
      return (
        <>
          <StyledButton
            sx={{
              ml: 2,
            }}
            onClick={() => onCellClick(_id)}
            variant="outlined"
            color="error"
            size="small"
          >
            DELETE
          </StyledButton>
        </>
      );
    },
  },
  // {
  //   Header: "VENDOR",
  //   accessor: "user",
  //   Cell: ({ value }) => {
  //     const user = value;
  //     return (
  //       <>
  //         <FlexBox sx={{ alignItems: "center" }}>
  //           <UserSymbol
  //             role={user.role}
  //             name={user.first_name ? user.first_name : user.email}
  //             size={25}
  //           />

  //           <Box
  //             sx={{
  //               marginLeft: "10px !important",
  //             }}
  //           >
  //             <Paragraph fontSize={13} color="text.secondary">
  //               {user.first_name + " " + user.last_name}
  //             </Paragraph>
  //           </Box>
  //         </FlexBox>
  //       </>
  //     );
  //   },
  // },
];
export default AddressColumnShape;
