import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  styled,
  Switch,
  TextareaAutosize,
} from "@mui/material";
import { H5, Paragraph, Small, Tiny } from "components/Typography";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import AppModal from "components/AppModal";
import AppTextField from "components/input-fields/AppTextField";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import Scrollbar from "components/ScrollBar";
import { H3, H6, H4 } from "components/Typography";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axios";
import * as Yup from "yup"; // component props interface
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import countries from "../../../../data/country";
import currencies from "../../../../data/currencies.json";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";

import { useTranslation } from "react-i18next";
import JSONPretty from "react-json-pretty";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward, Close } from "@mui/icons-material";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import CryptoImg from "components/shared/CryptoImg";
import { formatCurrency } from "utils/currencies";
import Platform from "components/shared/Platform";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import Dialer from "./Dialer";
import OfferService from "services/OffersService";

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 650,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

function EditOffer({ offer, open, onClose, userdata }) {
  const [isLoading, setIsLoading] = useState(false);
  console.log("offers", offer);
  const { t } = useTranslation();
  const [value, setValue] = useState("1");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const initialValues = {
    payment_window: offer && offer ? offer.offer.payment_window : "",
    payment_method_label:
      offer && offer.offer.payment_method_label != null
        ? offer.offer.payment_method_label
        : "",
    predefined_amount:
      offer && offer.offer.predefined_amount != null
        ? offer.offer.predefined_amount
        : "",
    payment_method_country_name:
      offer && offer.offer.payment_method_country_name
        ? offer.offer.payment_method_country_name
        : "US",

    range_min:
      offer && offer.offer.fiat_amount_range_min
        ? offer.offer.fiat_amount_range_min
        : "",
    range_max:
      offer && offer.offer.fiat_amount_range_max
        ? offer.offer.fiat_amount_range_max
        : "",
    margin: offer && offer.offer.margin ? offer.offer.margin : "",
    cap_amount: "",
    offer_cap: false,
    currency_code: offer && offer ? offer.offer.currency_code : "",
    // bank_accounts: offer && offer ? offer.offer.bank_accounts : "",

    offer_term: offer && offer ? offer.offer.offer_terms : "",
  };
  const fieldValidationSchema = Yup.object().shape({
    payment_method_label: Yup.string().required(
      "Payment Method label is Required!"
    ),
    predefined_amount: Yup.string().required("Predefined Amount is Required!"),
    // payment_method_country_name: Yup.string().required("Payment Country is Required!"),
    cap_amount: Yup.string().required("Capital Amount is Required!"),
    // bank_accounts: Yup.string().required("Bank accounts is Required!"),
  });
  const onhandleClose = () => {
    resetForm();
    onClose();
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues,

    validationSchema: fieldValidationSchema,
    onSubmit: async (data) => {
      setIsLoading(true);
      console.log("data", data);
      const resp = await OfferService.updateOffer(
        userdata._id,
        offer.paxful_offer_hash,
        data
      );
      if (resp && resp.status === true) {
        resetForm();
        onClose();
      }
      setIsLoading(false);
    },
  });

  const currencyOptions = Object.keys(currencies).map((currency) => {
    return {
      value: currency,
      label: currency,
    };
  });

  console.log("errors", errors);
  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <Box pb={4}>
        <FlexBetween>
          <H3 mb={3}>
            <FlexBox justifyContent="start" alignItems="center">
              Update &nbsp;
              {offer.offer.payment_method_name} (
              {offer.offer.fiat_currency_code})
              <Platform platform={offer.account.platform} size={20} /> {""}
              Offer
            </FlexBox>
            {/* <span>
              Update &nbsp;
              {offer.offer.payment_method_name} (
              {offer.offer.fiat_currency_code})
              <Platform
                className={"h-20px me-1 d-inline"}
                platform={offer.account.platform}
                size={20}
              />
              Offer
            </span>{" "} */}
          </H3>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </FlexBetween>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Payment Method
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginLeft: "8px" }}
                      >
                        <path
                          opacity="0.3"
                          d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z"
                          fill="currentColor"
                        />
                        <path
                          d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  }
                  value="1"
                />
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Pricing
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginLeft: "8px" }}
                      >
                        <path
                          opacity="0.3"
                          d="M18 22H6C5.4 22 5 21.6 5 21V8C6.6 6.4 7.4 5.6 9 4H15C16.6 5.6 17.4 6.4 19 8V21C19 21.6 18.6 22 18 22ZM12 5.5C11.2 5.5 10.5 6.2 10.5 7C10.5 7.8 11.2 8.5 12 8.5C12.8 8.5 13.5 7.8 13.5 7C13.5 6.2 12.8 5.5 12 5.5Z"
                          fill="currentColor"
                        />
                        <path
                          d="M12 7C11.4 7 11 6.6 11 6V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V6C13 6.6 12.6 7 12 7ZM15.1 10.6C15.1 10.5 15.1 10.4 15 10.3C14.9 10.2 14.8 10.2 14.7 10.2C14.6 10.2 14.5 10.2 14.4 10.3C14.3 10.4 14.3 10.5 14.2 10.6L9 19.1C8.9 19.2 8.89999 19.3 8.89999 19.4C8.89999 19.5 8.9 19.6 9 19.7C9.1 19.8 9.2 19.8 9.3 19.8C9.5 19.8 9.6 19.7 9.8 19.5L15 11.1C15 10.8 15.1 10.7 15.1 10.6ZM11 11.6C10.9 11.3 10.8 11.1 10.6 10.8C10.4 10.6 10.2 10.4 10 10.3C9.8 10.2 9.50001 10.1 9.10001 10.1C8.60001 10.1 8.3 10.2 8 10.4C7.7 10.6 7.49999 10.9 7.39999 11.2C7.29999 11.6 7.2 12 7.2 12.6C7.2 13.1 7.3 13.5 7.5 13.9C7.7 14.3 7.9 14.5 8.2 14.7C8.5 14.9 8.8 14.9 9.2 14.9C9.8 14.9 10.3 14.7 10.6 14.3C11 13.9 11.1 13.3 11.1 12.5C11.1 12.3 11.1 11.9 11 11.6ZM9.8 13.8C9.7 14.1 9.5 14.2 9.2 14.2C9 14.2 8.8 14.1 8.7 14C8.6 13.9 8.5 13.7 8.5 13.5C8.5 13.3 8.39999 13 8.39999 12.6C8.39999 12.2 8.4 11.9 8.5 11.7C8.5 11.5 8.6 11.3 8.7 11.2C8.8 11.1 9 11 9.2 11C9.5 11 9.7 11.1 9.8 11.4C9.9 11.7 10 12 10 12.6C10 13.2 9.9 13.6 9.8 13.8ZM16.5 16.1C16.4 15.8 16.3 15.6 16.1 15.4C15.9 15.2 15.7 15 15.5 14.9C15.3 14.8 15 14.7 14.6 14.7C13.9 14.7 13.4 14.9 13.1 15.3C12.8 15.7 12.6 16.3 12.6 17.1C12.6 17.6 12.7 18 12.9 18.4C13.1 18.7 13.3 19 13.6 19.2C13.9 19.4 14.2 19.5 14.6 19.5C15.2 19.5 15.7 19.3 16 18.9C16.4 18.5 16.5 17.9 16.5 17.1C16.7 16.8 16.6 16.4 16.5 16.1ZM15.3 18.4C15.2 18.7 15 18.8 14.7 18.8C14.4 18.8 14.2 18.7 14.1 18.4C14 18.1 13.9 17.7 13.9 17.2C13.9 16.8 13.9 16.5 14 16.3C14.1 16.1 14.1 15.9 14.2 15.8C14.3 15.7 14.5 15.6 14.7 15.6C15 15.6 15.2 15.7 15.3 16C15.4 16.2 15.5 16.6 15.5 17.2C15.5 17.7 15.4 18.1 15.3 18.4Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  }
                  value="2"
                />
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Other Settings
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginLeft: "8px" }}
                      >
                        <path
                          opacity="0.3"
                          d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z"
                          fill="currentColor"
                        />
                        <path
                          d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  }
                  value="3"
                />
              </TabList>
            </Box>
            <form onSubmit={handleSubmit}>
              <Box mt={2}>
                <TabPanel value="1">
                  <Scrollbar
                    style={{
                      maxHeight: 400,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <H6 mb={1}>Payment Window</H6>
                        <Dialer
                          values={values.payment_window}
                          name={"payment_window"}
                          setFieldValue={setFieldValue}
                          handleChange
                          errors
                          touched
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <H6 mb={1}>Payment Method Label</H6>
                        <AppTextField
                          fullWidth
                          size="small"
                          name="payment_method_label"
                          type={"text"}
                          placeholder="Payment method label"
                          onChange={handleChange}
                          value={values.payment_method_label}
                          helperText={
                            touched.payment_method_label &&
                            errors.payment_method_label
                          }
                          error={Boolean(
                            errors.payment_method_label &&
                              touched.payment_method_label
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <H6 mb={1}>Predefined Amount</H6>
                        <AppTextField
                          fullWidth
                          size="small"
                          name="predefined_amount"
                          type={"text"}
                          placeholder="Predefined amount"
                          onChange={handleChange}
                          value={values.predefined_amount}
                          helperText={
                            touched.predefined_amount &&
                            errors.predefined_amount
                          }
                          error={Boolean(
                            errors.predefined_amount &&
                              touched.predefined_amount
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <H6 mb={1}>Payment Country</H6>
                        <Select
                          sx={{ height: 39, width: "100%" }}
                          onChange={handleChange}
                          name="payment_method_country_name"
                          value={values.payment_method_country_name || "US"}
                        >
                          {countries.map(({ name, code }) => (
                            <MenuItem key={name} value={code}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>

                        {/* <AppTextField
                          fullWidth
                          size="small"
                          name="payment_method_country_name"
                          type={"text"}
                          placeholder="Payment country"
                          onChange={handleChange}
                          value={values.payment_method_country_name}
                          helperText={
                            touched.payment_method_country_name && errors.payment_method_country_name
                          }
                          error={Boolean(
                            errors.payment_method_country_name && touched.payment_method_country_name
                          )}
                        /> */}
                      </Grid>
                    </Grid>
                  </Scrollbar>
                </TabPanel>
                <TabPanel value="2">
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <H6 mb={1}>Minimum Range</H6>
                      <Dialer
                        values={values.range_min}
                        name={"range_min"}
                        setFieldValue={setFieldValue}
                        handleChange
                        errors
                        touched
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <H6 mb={1}>Maximum Range</H6>
                      <Dialer
                        values={values.range_max}
                        name={"range_max"}
                        setFieldValue={setFieldValue}
                        handleChange
                        errors
                        touched
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <H6 mb={1}>Margin</H6>
                      <Dialer
                        values={values.margin}
                        name={"margin"}
                        setFieldValue={setFieldValue}
                        handleChange
                        errors
                        touched
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <H6 mb={1}>Offer Cap Amount</H6>
                      <AppTextField
                        fullWidth
                        size="small"
                        name="cap_amount"
                        type={"text"}
                        placeholder="Offer cap amount"
                        onChange={handleChange}
                        value={values.cap_amount}
                        helperText={touched.cap_amount && errors.cap_amount}
                        error={Boolean(errors.cap_amount && touched.cap_amount)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <H6 mb={1}>Offer Cap Status</H6>

                      <Switch
                        checked={value.offer_cap}
                        onChange={({ target }) => {
                          setFieldValue("offer_cap", target.checked);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <H6 mb={1}>Currency</H6>

                      <Select
                        sx={{ height: 39, width: "100%" }}
                        onChange={handleChange}
                        name="currency_code"
                        value={values.currency_code || "US"}
                        placeholder="currency"
                      >
                        {currencyOptions.map(({ value, label }) => (
                          <MenuItem key={label} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 mb={1}>Bank Accounts</H6>

                      <AppTextField
                        fullWidth
                        size="small"
                        name="bank_accounts"
                        type={"text"}
                        placeholder="Bank accounts "
                        onChange={handleChange}
                        value={values.bank_accounts}
                        helperText={
                          touched.bank_accounts && errors.bank_accounts
                        }
                        error={Boolean(
                          errors.bank_accounts && touched.bank_accounts
                        )}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="3">
                  <Grid item xs={6}>
                    <AppTextField
                      fullWidth
                      size="small"
                      name="offer_term"
                      type={"text"}
                      placeholder="Offer terms "
                      onChange={handleChange}
                      value={values.offer_term}
                      helperText={touched.offer_term && errors.offer_term}
                      error={Boolean(errors.offer_term && touched.offer_term)}
                      multiline
                      rows={10} // Adjust the number of rows (height of
                      variant="outlined" // Can also be 'filled' or
                    />
                  </Grid>
                </TabPanel>
              </Box>
              <FlexBox justifyContent="flex-center" gap={2} marginTop={4}>
                <Button
                  fullWidth
                  size="small"
                  variant="outlined"
                  onClick={onhandleClose}
                >
                  Cancel
                </Button>
                <LoadingButton
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  Update
                </LoadingButton>
              </FlexBox>
            </form>
          </TabContext>
        </Box>
      </Box>
    </StyledAppModal>
  );
}

export default EditOffer;
