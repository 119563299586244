import React from "react";
// import { getCountryNameByCode } from "../../helpers/utils";

// import UserSymbol from "./UserSymbol";
import CountryFlag from "./CountryFlag";
import OnlineStatus from "./OnlineStatus";
import UserSymbol from "./UserSymbol";
import FlexBox from "components/flexbox/FlexBox";
import RoleBadge from "utils/RoleBadge";
import Timezone from "./Timezone";
import { Box } from "@mui/material";
import { Paragraph, Small } from "components/Typography";
import ComputerIcon from "@mui/icons-material/Computer";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LanguageIcon from "@mui/icons-material/Language";
import BadgeMui from "./BadgeMui";

function UserInfo({ user, is_live, showOnlineStatus, devices, showOnline }) {
  console.log("USER", user);
  return (
    <>
      <FlexBox
        sx={{
          alignItems: "",
        }}
        className="d-flex align-items-start text-start"
      >
        {/* <UserSymbol user={user} size={30} fw={"semibold"} showOnline={true} /> */}
        <UserSymbol
          showOnline={showOnline}
          size={30}
          user={user}
          isBottomZero={40}
        />
        <Box ml={1}>
          <FlexBox
            sx={{
              alignItems: "center",
            }}
          >
            <Small
              fontSize={13}
              className="mb-1 text-gray-800 tooltip-user-name"
            >
              {user.first_name} {user.last_name}
            </Small>
            {user.country && (
              <Small ml={1} className="ms-2">
                <CountryFlag
                  code={user.country}
                  width="18px"
                  showToolTip={false}
                />
              </Small>
            )}
          </FlexBox>
          {user.timezone && (
            <FlexBox className="d-flex mt-1 mb-1">
              {/* <UserRole user={user} className="me-1" /> */}
              <RoleBadge role={user.role} />
              <Box ml={1}>
                <Timezone
                  className={"timezone-tooltip-badge"}
                  timezone={user.timezone}
                  showTooltip={false}
                  id={`member-${user._id}`}
                />
              </Box>
            </FlexBox>
          )}

          {showOnlineStatus && user?.role == "va" ? (
            ""
          ) : (
            <span>
              <OnlineStatus
                member={user}
                className={"tooltip-status"}
                is_live={is_live}
                user={user}
              />
            </span>
          )}
          {user?.role == "va" && (
            <Box>
              <StatusBadges
                color={"success.main"}
                title={"Completed"}
                style={{ marginRight: "10px" }}
              />
            </Box>
          )}
        </Box>
      </FlexBox>
      <FlexBox mt={1} sx={{ justifyContent: "flex-end" }}>
        {devices && devices.includes("web") && <LanguageIcon />}
        {devices && devices.includes("android") && devices.includes("ios") && (
          <PhoneAndroidIcon />
        )}
      </FlexBox>
    </>
  );
}

UserInfo.defaultProps = {
  showOnlineStatus: true,
};

export default UserInfo;

export const StatusBadges = ({ color, title }) => {
  return (
    <Small
      sx={{
        width: 100,
        fontSize: 10,
        color: "#fff",
        borderRadius: "4px",
        textAlign: "center",
        padding: ".2rem 0.7rem",
        backgroundColor: color,
        marginRight: "0.5rem",
      }}
    >
      {title}
    </Small>
  );
};
