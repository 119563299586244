import { ContentCopy, Edit } from "@mui/icons-material";
import { IconButton, styled, Button, Box, Tooltip } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import FlexBox from "components/flexbox/FlexBox";
import { Link } from "react-router-dom";
import { Tiny } from "components/Typography";
import { ArrowOutward, ArrowRight } from "@mui/icons-material";
import EditButton from "page-sections/profiles/profile/EditButton";
import PasswordButton from "page-sections/profiles/profile/PasswordButton";

import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag from "components/shared/CountryFlag";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import TradeStatus from "components/shared/TradeStatus";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import BuyerProfilePic from "components/shared/BuyerProfilePic";
import PaxfulAccount from "components/shared/PaxfulAccount";
import { FlagAndPlatform } from "page-sections/profiles/profile/Trades/PartnerDetail";
import UserSymbol from "components/shared/UserSymbol";
import OfferType from "components/shared/OfferType";
import { formatCurrency } from "utils/currencies";
import CryptoBalance from "components/shared/CryptoBalance";
import FormattedBalance from "components/shared/FormattedBalance";
import CustomPopover from "components/CustomPopover";
import { VATooltip } from "page-sections/profiles/profile/Trades/Chat";
import ATooltip from "components/shared/ATooltip";
import UserInfo from "components/shared/UserInfo";
import platforms from "data/platforms";
import { isEmptyArray } from "formik";
import DeleteButton from "page-sections/profiles/profile/DeleteButton";
import notification from "helpers/notifications";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};
const HireVirtualAssistantColumn = (edit, deleted, changePassword) => {
  return [
    {
      Header: "ID",
      accessor: "_id",
      Cell: ({ value }) => (
        <>
          <Box
            sx={{
              maxWidth: "40px",
              cursor: "pointer",
            }}
          >
            <ATooltip content={value}>
              <Span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  copyTextToClipboard(value);
                }}
              >
                {value.slice(0, 2)}...
                {value.slice(value.length - 3, value.length)}
              </Span>
            </ATooltip>
          </Box>
        </>
      ),
    },
    {
      Header: () => <Box sx={{ marginLeft: "10px" }}>DATE</Box>,
      accessor: "createdAt",
      Cell: ({ value }) => {
        return (
          <Box
            sx={{
              minWidth: "148px",
            }}
          >
            <Paragraph fontSize={13} color="text.secondary">
              <FormattedDateTime date={value} />
            </Paragraph>
            <Small
              sx={{
                width: 100,
                fontSize: 10,
                color: "#fff",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".2rem 1rem",
                backgroundColor: "lightgrey",
                color: "black",
              }}
            >
              <FromNow date={value} />
            </Small>
          </Box>
        );
      },
    },
    {
      Header: () => <Box sx={{ marginLeft: "12px" }}>NAME</Box>,
      accessor: "name",
      Cell: ({ row }) => {
        let { email, first_name, last_name, country, mobile_number } =
          row.original;
        if (!email) {
          email = mobile_number;
        }
        return (
          <>
            <FlexBox>
              <Box mt={1}>
                <UserSymbol user={row.original} size={30} />
              </Box>
              <Box ml={1}>
                <FlexBox sx={{ alignItems: "center" }}>
                  <Paragraph
                    fontSize={13}
                    color="text.secondary"
                    fontWeight={"bold"}
                  >
                    {" "}
                    {first_name + " " + last_name}
                  </Paragraph>
                  <Span ml={1}>
                    <CountryFlag code={country} width={"20px"} />
                  </Span>
                </FlexBox>

                <Paragraph fontSize={13} color="text.secondary">
                  {email}
                </Paragraph>
              </Box>
            </FlexBox>
          </>
        );
      },
    },
    {
      Header: () => <Box sx={{ marginLeft: "10px" }}>TIMEZONE</Box>,
      accessor: "timezone",
      Cell: ({ value }) => (
        <Paragraph fontSize={13} color="text.secondary">
          {value}
        </Paragraph>
      ),
    },
    {
      Header: () => <Box sx={{ marginLeft: "6px" }}>EXPERIENCE</Box>,
      accessor: "experience",
      Cell: ({ value }) => (
        <Paragraph fontSize={13} color="text.secondary">
          {value}
        </Paragraph>
      ),
    },

    {
      Header: "ACTIONS",
      accessor: "actions",
      Cell: ({ row }) => {
        return (
          <>
            {/* <StyledButton
              sx={{
                ml: 2,
              }}
              onClick={() => edit(row.original)}
              variant="outlined"
              size="small"
            >
              Edit
            </StyledButton> */}

            {/**/}
            <box>
              <FlexBox justifyContent="center">
                <ATooltip content={"Edit "}>
                  <EditButton onClick={() => edit(row.original)} />
                </ATooltip>
                {/* <ATooltip content={"Change Password"}>
                  <PasswordButton
                    onClick={() => changePassword(row.original)}
                  />
                </ATooltip> */}
                <ATooltip content={"Delete Virtual assistant"}>
                  {<DeleteButton onClick={() => deleted(row.original)} />}
                </ATooltip>
              </FlexBox>
            </box>

            {/* <StyledButton
              sx={{
                ml: 2,
              }}
              onClick={() => deleted(row.original)}
              variant="outlined"
              size="small"
              color="error"
            >
              Delete
            </StyledButton> */}
          </>
        );
      },
    },
  ];
};
export default HireVirtualAssistantColumn;
