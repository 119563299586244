import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5, H6, Small } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import InvoiceListTable from "page-sections/invoice/list";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { copyTextToClipboard, searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components

import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import WalletService from "services/WalletService";
import { LoadingButton } from "@mui/lab";
import WalletList from "./WalletList";
import supportedAssets from "data/supportedAssets";
import rateService from "services/rateService";
import FlexBox from "components/flexbox/FlexBox";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getAtlantisAssetBalance, getFormatedFiatAmount } from "utils/crypto";
import { formatCurrency } from "utils/currencies";
import { UserContextData } from "App";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const Wallet = ({ openBlockTransactions }) => {
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  // ================================
  // wallet Transactions
  // =================================
  const { userdata } = useContext(UserContext);
  console.log("userdata", userdata.uuid);

  const { liveRate } = useContext(UserContextData);

  const [isLoading, setisLoading] = useState(false);
  const [fiatRates, setFiatRate] = useState(null);
  const [runUseEffect, setRunUseEffect] = useState(true);

  const [data, setdata] = useState([]);
  const [sortedData, setSortedData] = useState([]);

  const getFiatRate = async () => {
    setisLoading(true);
    const resp = await rateService.getFiatRate();
    setFiatRate(resp.data.rates);
    setisLoading(false);
  };

  useEffect(() => {
    if (runUseEffect) {
      setRunUseEffect(false);
      return; // Skip the effect on initial render
    }
    supportedAssets.forEach((item) => {
      let asset = {};
      let assestbalance = userdata?.wallet[0]?.asset_balance;
      let _assest = userdata?.wallet[0]?.assets;
      for (let key in assestbalance) {
        if (item.assetID == key) {
          asset.id = key;
          asset.asset_balance = assestbalance[key];
          asset.asset_info = _assest[key];
          asset.blockchain = item.blockchain;
          asset.name = item.title;
          asset.symbol = item.code;
          asset.assetID = item.assetID;
          asset.link = item.explorerLink;
          asset.usd_balance =
            assestbalance[key]?.crypto_balance * fiatRates[item.nativeAsset];
          asset.network_name = item.network;

          // assestbalance[key]?.crypto_balance;
        }

        // console.log(
        //   "assestbalance[key]?.crypto_balance",
        //   assestbalance[key]?.crypto_balance
        // );
        // fiatRates[key];

        //
      }
      setdata((pv) => [...pv, asset]);

      // data.push(asset);
    });
  }, [fiatRates]);

  // =========================================

  useEffect(() => {
    getFiatRate();
  }, []);

  let sorted =
    data &&
    data.sort(function (a, b) {
      let _a = parseFloat(
        getFormatedFiatAmount(
          a.asset_balance?.crypto_balance,
          a.assetID
        ).substring(1)
      ); // Remove the first character (the "$" sign)
      let _b = parseFloat(
        getFormatedFiatAmount(
          b.asset_balance?.crypto_balance,
          b.assetID
        ).substring(1)
      ); // Remove the first character (the "$" sign)
      console.log("a", _a);
      console.log("_b", _b);

      return _b - _a;
    });

  const reload = () => {
    getFiatRate();
    //   //   getWalletTransactions(userdata._id);
  };

  const getTotalBalance = () => {
    if (userdata?.wallet[0]?.asset_balance) {
      const { totalBalance } = getAtlantisAssetBalance(
        userdata?.wallet[0]?.asset_balance
      );
      return formatCurrency(totalBalance);
    }
  };
  console.log("userdata?.wallet[0]", userdata?.wallet[0]);

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <Box>
            <FlexBox sx={{ alignItems: "center" }}>
              <H5>{t("Venva Wallet")}</H5>
              {userdata?.uuid && (
                <FlexBox sx={{ alignItems: "center" }} ml={2}>
                  <Small color="text.secondary" fontSize={13}>
                    {userdata.uuid}
                  </Small>
                  <ContentCopyIcon
                    sx={{
                      marginLeft: "5px",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    color={"primary"}
                    onClick={() => copyTextToClipboard(userdata.uuid)}
                  />
                </FlexBox>
              )}
            </FlexBox>
            <Box alignItems="center">
              <Small mr={2} sx={{ fontWeight: "bold" }}>
                {t("Total Holding")}
              </Small>
              <Small sx={{ fontWeight: "bold" }} color="text.secondary">
                {getTotalBalance()}
              </Small>
            </Box>
            <Box alignItems="center">
              <Small mr={2} sx={{ fontWeight: "bold" }}>
                {t("Vault ID")}
              </Small>
              <Small sx={{ fontWeight: "bold" }} color="text.secondary">
                {userdata?.wallet[0]?.vaultId}
              </Small>
            </Box>
          </Box>
        </Stack>

        <StyledStack alignItems="center" direction="row" gap={2}>
          <LoadingButton
            onClick={() => reload()}
            variant="contained"
            loading={isLoading}
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data &&
        sortedData && (
          <WalletList
            wallet={sorted}
            // setOption={setOption}
            // setTabValue={setTabValue}
            openBlockTransactions={openBlockTransactions}
          />
        )
      )}
    </Box>
  );
};

// const AlphabetCount = ({ items }) => {
//   const getAlphabetCount = (index) => {
//     const alphabetCounts = [
//       "zero",
//       "first",
//       "second",
//       "third",
//       "fourth",
//       "fifth",
//       "six",
//       "seven",
//       "eight",
//     ]; // Add more if needed

//     if (index < alphabetCounts.length) {
//       return alphabetCounts[index];
//     }
//     // Return regular index if it exceeds the defined alphabet counts
//     return index;
//   };

//   return (
//     <ul>
//       {items.map((item, index) => (
//         <li key={index}>{getAlphabetCount(index)}</li>
//       ))}
//     </ul>
//   );
// };

export default Wallet;
