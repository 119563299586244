import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward, ContentCopy } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import CryptoImg from "components/shared/CryptoImg";
import {
  formatCryptoDecimal,
  formatCurrency,
  normalizeCrypto,
} from "utils/currencies";
import FlexBox from "components/flexbox/FlexBox";
import { capitalizeFLetter } from "utils/utils";
import cryptoAssets from "data/supportedAssets";
import notification from "helpers/notifications";
import ATooltip from "components/shared/ATooltip";

const paymentFunction = (value) => {
  switch (value) {
    case "subscription":
      return "New Membership";
    case "subscription-renew":
      return "Membership Renewal";
    case "subscription-upgrade":
      return "Upgrade Membership";
    case "va":
      return "Hire VA";
    case "trading":
      return "Trading Volume Topup";

    default:
    // code block
  }
};

const paymentMethod = (value, data) => {
  switch (value) {
    case "wallet":
      return "Crypto";
    case "lightning":
      return "Lightning";
    case "stripe":
      return `Credit Card (${
        data?.cardDetails?.brand + " " + data?.cardDetails?.last4
      })`;
    case "paxfulpay":
      return "Paxful Pay";

    default:
    // code block
  }
};

export const getCreditDescription = (blockchain_txn) => {
  if (blockchain_txn) {
    let desc = "from Venva Wallet";
    switch (blockchain_txn.network) {
      case "lightning":
        desc = "via Lightning Network";
        break;
      case "paxfulpay":
        desc = "using Paxful Pay";
        break;
    }
    return (
      <>
        Credited with{" "}
        <CryptoImg size={18} currency={blockchain_txn.crypto_symbol} />{" "}
        {blockchain_txn.crypto_amount} {blockchain_txn.crypto_symbol} (
        {formatCurrency(blockchain_txn.fiat_amount)}) {desc}
      </>
    );
  }

  // if (transaction.stripe_txn) {
  //   return `Credited with ${formatCurrency(
  //     transaction.fiat_amount
  //   )} Credit Card Payment`;
  // }
  // return transaction.description;
};
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};
const AtlantisRevenueColumShape = [
  {
    Header: "ID",
    accessor: "_id",
    Cell: ({ value }) => (
      <>
        <Box
          sx={{
            maxWidth: "40px",
            cursor: "pointer",
          }}
        >
          <ATooltip content={value}>
            <Span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyTextToClipboard(value);
              }}
            >
              {value.slice(0, 2)}...
              {value.slice(value.length - 3, value.length)}
            </Span>
          </ATooltip>
        </Box>
      </>
    ),
  },
  // {
  //   Header: "DATE",
  //   accessor: "createdAt",
  //   Cell: ({ value }) => (
  //     <Paragraph fontSize={13} color="text.secondary">
  //       <FormattedDateTime date={value} />
  //     </Paragraph>
  //   ),
  // },

  {
    Header: () => <Box sx={{ marginLeft: "12px" }}>DATE</Box>,
    accessor: "createdAt",
    Cell: ({ value, row }) => {
      console.log("ROW", row);
      const { blockchain_transaction_id } = row.original;
      const userId = row.original?.user[0]?._id;
      // const link =
      //   blockchain_transaction_id && userId
      //     ? `/dashboard/users/${userId}/transactions/${blockchain_transaction_id}`
      //     : `/dashboard/atlantis-revenue`;

      return (
        // <Link to={link}>
        <>
          <Paragraph fontSize={13} color="text.secondary">
            <FormattedDateTime date={value} />
          </Paragraph>

          <Small
            sx={{
              width: 100,
              fontSize: 10,
              color: "#fff",
              borderRadius: "4px",
              textAlign: "center",
              padding: ".2rem 1rem",
              backgroundColor: "lightgrey",
              color: "black",
            }}
          >
            <FromNow date={value} />
          </Small>
        </>
        // </Link>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "12px" }}>VENDOR</Box>,
    accessor: "user",
    Cell: ({ value }) => {
      const user = value[0];
      console.log("value", value);
      return (
        <>
          {user && user.first_name != null && (
            <FlexBox sx={{ alignItems: "center" }}>
              <UserSymbol
                user={user}
                size={25}
                showOnline={true}
                isBottomZero={0}
              />

              <Box
                sx={{
                  marginLeft: "10px !important",
                }}
              >
                <Paragraph fontSize={13} color="text.secondary">
                  <Link to={`/dashboard/users/${user._id}`}>
                    {user.first_name + " " + user.last_name}{" "}
                  </Link>
                </Paragraph>
              </Box>
            </FlexBox>
          )}
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>METHOD</Box>,
    accessor: "method",
    Cell: ({ row }) => {
      const { blockchain_transaction, method, stripe_transaction } =
        row.original;
      console.log("stripe_transaction", stripe_transaction);

      return (
        <Paragraph fontSize={13} color="text.secondary">
          {/* {method && capitalizeFLetter(method == "wallet" ? "Crypto" : method)} */}
          {method && paymentMethod(method, stripe_transaction[0])}
        </Paragraph>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>USD AMOUNT</Box>,
    accessor: "usd_amount",
    Cell: ({ row }) => {
      const usd_amount = row.original?.payment_method[0]?.usd_amount;
      console.log("usd_amount", usd_amount);
      return (
        // <Paragraph fontSize={13} color="text.secondary">
        //   {usd_amount && normalizeCrypto(usd_amount)}
        // </Paragraph>
        <>
          {usd_amount && (
            <Paragraph fontWeight={800} fontSize={13} color={"success.main"}>
              {formatCurrency(usd_amount)}
            </Paragraph>
          )}
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>PAYMENT FOR</Box>,
    accessor: "payment_method",
    Cell: ({ row }) => {
      const type = row.original?.payment_method[0]?.type;

      return <Box>{type && paymentFunction(type)}</Box>;
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "12px" }}>CRYPTO CODE</Box>,
    accessor: "crypto_code",
    Cell: ({ row }) => {
      const { blockchain_transaction, crypto_code } = row.original;
      return (
        <FlexBox sx={{ alignItems: "center" }}>
          {crypto_code && <CryptoImg currency={crypto_code} size={25} />}
          {blockchain_transaction[0]?.blockchain && (
            <Small ml={1}>
              {capitalizeFLetter(blockchain_transaction[0]?.blockchain)}
            </Small>
          )}
        </FlexBox>
      );
    },
  },
  {
    Header: "CRYPTO AMOUNT",
    accessor: "crypto_amount",
    Cell: ({ row }) => {
      let { crypto_amount, crypto_code, blockchain_transaction } = row.original;
      const foundAsset = cryptoAssets.find(
        ({ assetID }) => blockchain_transaction[0]?.assetId == assetID
      );
      console.log("foundAsset", foundAsset);
      return (
        <Paragraph fontSize={13} color="text.secondary">
          {/* {crypto_amount && formatCurrency(crypto_amount)} */}
          {crypto_amount &&
            foundAsset &&
            formatCryptoDecimal(crypto_amount, foundAsset.decimals)}
        </Paragraph>
      );
    },
  },
];

export default AtlantisRevenueColumShape;
