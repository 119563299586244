import {
  ArrowBack,
  ChevronRight,
  ReportRounded,
  Rowing,
  Send,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputBase,
  Stack,
  styled,
  useMediaQuery,
} from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import SearchInput from "components/input-fields/SearchInput";
import Scrollbar from "components/ScrollBar";
import { H4, H5, Paragraph, Small, Tiny, Span } from "components/Typography";
import AttachmentDiagonal from "icons/AttachmentDiagonal";
import FileOutlined from "icons/FileOutlined";
import Image from "icons/Image";
import Link from "icons/Link";
import Common from "page-sections/chats/chat-v1/Common";
import FileType from "page-sections/chats/chat-v1/FileType";
import IncomingMsg from "page-sections/chats/chat-v1/IncomingMsg";
import OutgoingMsg from "page-sections/chats/chat-v1/OutgoingMsg";
import PersonInfo from "page-sections/chats/chat-v1/PersonInfo";
import RecentChatItem from "page-sections/chats/chat-v1/RecentChatItem";
import { useState, useEffect } from "react";
import RECENT_CHATS from "__fakeData__/recent_chats";
// import { lightTheme } from "../../constants"; // styled components
import { lightTheme } from "../../../../constants";
import { H3, H6 } from "components/Typography";
import TradeStatus, { TradesStatus } from "components/shared/TradeStatus";
import TradeServices from "services/TradeService";
import { useParams } from "react-router";
import UserService from "services/UserService";
import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import OfferType from "components/shared/OfferType";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import UserSymbol from "components/shared/UserSymbol";
import { formatCurrency, normalizeCrypto } from "utils/currencies";
import Badge from "utils/Badge";
import BackButton from "./BackButton";
import Platform from "components/shared/Platform";
import ATooltip from "components/shared/ATooltip";
import { AttachmentIcon } from "components/shared/AttachmentIcon";
import { ChatIcon } from "components/shared/ChatIcon";
import CountryFlag from "components/shared/CountryFlag";
import BadgeMui from "components/shared/BadgeMui";
import platforms from "data/platforms";
import PhoneNumberFormatter from "components/shared/PhoneNumberFormatter";
import DateTime from "components/shared/DateTime";
import { ProfileImageWithAccount } from "components/shared/PartnerDetails";
import UserInfo from "components/shared/UserInfo";
import ReleasedByVA from "components/ReleasedByVa";
import BuyerProfilePic from "components/shared/BuyerProfilePic";
import AllKyc from "./AllKyc";
import { SettingsContext } from "contexts/settingsContext";
import Countdown from "react-countdown";
import moment from "moment-timezone";
import TimeElapsedTimer from "components/TimeElapsedTimer";

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
}));
const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
  backgroundColor: lightTheme(theme)
    ? theme.palette.action.hover
    : theme.palette.divider,
}));
const ToggleBtn = styled(Box)(({ theme, screen = "md" }) => ({
  top: 20,
  zIndex: 1,
  padding: 5,
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  position: "absolute",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up(screen)]: {
    display: "none",
  },
}));

const getSecurityType = (val) => {
  let message = "";
  switch (val) {
    case "pin":
      message = "Trade PIN";
      break;
    case "app":
      message = "Vendor App Approval";
      break;
  }
  return message;
};

const countDownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (!completed) {
    return (
      <span className="fs-7">
        {hours > 0 ? `${hours}h` : ""} {minutes > 0 ? `${minutes}m` : ""}{" "}
        {seconds}s
      </span>
    );
  }
};
function TradeDetail({ trade, onBack }) {
  const { settings, saveSettings } = useContext(SettingsContext);
  useEffect(() => {
    console.log("calling it");
    window.scrollTo(0, 0);
  }, [trade]);

  // console.log("trade", trade);
  console.log("security pin", trade.trade_security);
  const [openRightDrawer, setOpenRightDrawer] = useState(false);

  const getTradeSummary = (offer_type, status) => {
    if (offer_type == "sell") {
      return (
        <span className="text-gray-600">
          You{" "}
          {status == "cancelled"
            ? "would have paid"
            : status == "released"
            ? "paid"
            : "are paying"}{" "}
          <Box sx={{ display: "inline-flex ", alignItems: "center" }}>
            <img
              src={`/media/svg/coins/${trade.trade.crypto_currency_code.toLowerCase()}.svg`}
              alt={trade.trade.crypto_currency_code}
              width={16}
              className="h-15px ms-1 me-1"
            />
          </Box>{" "}
          <Small mr={0.5} fontSize={16} fontWeight={"bold"}>
            {normalizeCrypto(
              trade.trade.crypto_amount_total,
              trade.trade.crypto_currency_code
            )}{" "}
            {trade.trade.crypto_currency_code}
          </Small>{" "}
          to <strong>{trade.buyer.username}</strong> and{" "}
          {status == "cancelled"
            ? "would have received"
            : status == "released"
            ? "received"
            : "are receiving"}{" "}
          <strong>
            {formatCurrency(
              trade.trade.fiat_amount_requested,
              trade.trade.fiat_currency_code
            )}{" "}
            {trade.trade.fiat_currency_code}
          </strong>{" "}
          via <strong>{trade.trade.payment_method_name}</strong>.
        </span>
      );
    } else {
      return (
        <Box alignItems="center" className="text-gray-600">
          You{" "}
          {status == "cancelled"
            ? "would have paid"
            : status == "released"
            ? "paid"
            : "are paying"}{" "}
          <strong>
            {formatCurrency(
              trade.trade.fiat_amount_requested,
              trade.trade.fiat_currency_code
            )}{" "}
            {trade.trade.fiat_currency_code}
          </strong>{" "}
          with <strong>{trade.trade.payment_method_name}</strong> and{" "}
          {status == "cancelled"
            ? "would have received"
            : status == "released"
            ? "received"
            : "are receiving"}{" "}
          <Box sx={{ display: "inline-flex ", alignItems: "center" }} mx={0.5}>
            <Small mr={0.5} fontSize={16} fontWeight={"bold"}>
              {normalizeCrypto(
                trade.trade.crypto_amount_total,
                trade.trade.crypto_currency_code
              )}{" "}
              {trade.trade.crypto_currency_code}
            </Small>{" "}
            <Small mx={0.5}>
              <img
                src={`/media/svg/coins/${trade.trade.crypto_currency_code.toLowerCase()}.svg`}
                alt={trade.trade.crypto_currency_code}
                className="h-15px ms-1 me-1"
                width={20}
              />
            </Small>
          </Box>
          from <strong>{trade.buyer.username}</strong>.
        </Box>
      );
    }
  };

  const openDrawer = () => {
    setOpenRightDrawer(true);
  };
  return (
    <Card
      sx={{
        maxHeight: "900vh",
        height: "100%",
        pb: 1,
      }}
    >
      <Box p={3}>
        <FlexBox
          sx={{
            width: "100%",
          }}
          alignItems="center"
        >
          <BackButton onClick={onBack} />
          <H5 mr={0.5}>{trade.trade.payment_method_name}</H5>
          <Platform platform={trade.account.platform} size={22} />
          <FlexBox
            ml={2}
            sx={{ justifyContent: "space-between", width: "68%" }}
          >
            <Small fontSize={10}>
              <OfferType value={trade.trade.offer_type} />
            </Small>

            <span className="">
              <Small fontSize={10} mr={2}>
                {trade && <TradeStatus _trade={trade} />}
              </Small>
              {trade.status === "new" && (
                <>
                  <ATooltip content="Time Remaining">
                    <Small
                      fontSize={12}
                      fontWeight={600}
                      color="error.main"
                      className="text-danger fs-7 fw-bolder cursor-default"
                    >
                      <Countdown
                        date={
                          moment(trade.original.startedAt)
                            .add(trade.trade.payment_window, "minutes")
                            .unix() * 1000
                        }
                        renderer={countDownRenderer}
                      />
                    </Small>
                  </ATooltip>
                </>
              )}
              {trade.status === "paid" && (
                <>
                  <ATooltip content="Time Elapsed">
                    <Small color="primary.main" fontSize={12} fontWeight={600}>
                      <TimeElapsedTimer
                        start={trade.startedAt}
                        id={trade._id}
                      />
                    </Small>
                  </ATooltip>
                </>
              )}
            </span>
          </FlexBox>
        </FlexBox>
      </Box>

      <Divider />

      <Box p={3}>
        <Box mb={2}></Box>
        <Box mb={2}>
          <Small fontSize={12} color="text.secondary">
            Trade Summary
          </Small>

          {/* <Paragraph color="text.secondary"> */}
          {/* <Small color="text.secondary" fontSize={14}> */}
          <Box>{getTradeSummary(trade.trade.offer_type, trade.status)}</Box>
          {/* </Small>   */}
          {/* </Paragraph> */}
          <FlexBox sx={{ justifyContent: "space-between" }}>
            <FlexBox mt={1} alignItems="center">
              {/* <PaxfulProfilePic size={35} account={trade.account} /> */}
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <PaxfulProfilePic size={35} account={trade.account} />
                <Box
                  sx={{
                    position: "absolute",
                    top: -7,
                    bottom: 0,
                    left: 25,
                    right: 0,
                  }}
                >
                  <Platform platform={trade.account.platform} size={22} />
                </Box>
              </Box>
              <Box ml={0.5}>
                <FlexBox>
                  <Paragraph mr={0.5} fontSize={15}>
                    {trade.account.paxful_username}
                  </Paragraph>
                  {/* <Platform platform={trade.account.platform} size={25} /> */}
                  <Box ml={0.5}>
                    {" "}
                    <BadgeMui
                      color={
                        trade.trade.offer_type == "buy"
                          ? "success.main"
                          : "error.main"
                      }
                    >
                      {trade.trade.offer_type == "buy" ? "Buyer" : "Seller"}
                    </BadgeMui>
                  </Box>
                </FlexBox>
                <FlexBox>
                  <Paragraph fontSize={15}>
                    {formatCurrency(
                      trade.trade.fiat_amount_requested,
                      trade.trade.fiat_currency_code
                    )}{" "}
                    {trade.trade.fiat_currency_code}
                  </Paragraph>
                  <Paragraph
                    color="text.secondary"
                    sx={{
                      marginLeft: "7px",
                    }}
                    fontSize={15}
                  >
                    {normalizeCrypto(
                      trade.trade.crypto_amount_total,
                      trade.trade.crypto_currency_code
                    )}{" "}
                    {trade.trade?.crypto_currency_code}
                  </Paragraph>
                </FlexBox>
              </Box>
            </FlexBox>

            <FlexBox mt={1} alignItems="center">
              {trade?.buyer && (
                <ProfileImageWithAccount
                  account={trade.buyer}
                  buyer
                  platform={trade.account.platform}
                  platformSize={22}
                />
              )}
              <Box ml={1.5}>
                <FlexBox sx={{ alignItems: "center" }}>
                  <a
                    style={{
                      marginLeft: "3px",
                      color: "black",
                    }}
                    href={
                      `https://${trade.account.platform}.com/user/` +
                      trade.buyer.username
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Paragraph
                      mr={0.5}
                      fontSize={15}
                      color={settings.theme == "dark" ? "white" : "black"}
                    >
                      {trade.buyer.username}
                    </Paragraph>
                  </a>

                  <CountryFlag
                    width={"18px"}
                    code={trade.partner_location.detected_location.iso}
                  />
                  <Box ml={0.5}>
                    {" "}
                    <BadgeMui
                      color={
                        trade.trade.offer_type == "buy"
                          ? "error.main"
                          : "success.main"
                      }
                    >
                      {trade.trade.offer_type == "buy" ? "Seller" : "Buyer"}
                    </BadgeMui>
                  </Box>
                </FlexBox>
                <FlexBox>
                  <Paragraph fontSize={15}>
                    {formatCurrency(
                      trade.trade.fiat_amount_requested,
                      trade.trade.fiat_currency_code
                    )}{" "}
                    {trade.trade.fiat_currency_code}
                  </Paragraph>
                  <Paragraph
                    color="text.secondary"
                    sx={{
                      marginLeft: "7px",
                    }}
                    fontSize={15}
                  >
                    {normalizeCrypto(
                      trade.trade.crypto_amount_total,
                      trade.trade.crypto_currency_code
                    )}{" "}
                    {trade.trade.crypto_currency_code}
                  </Paragraph>
                </FlexBox>
              </Box>
            </FlexBox>
          </FlexBox>
        </Box>

        <Box mb={2}>
          <Small fontSize={12} color="text.secondary">
            {platforms[trade.account.platform].title} Trade Hash
          </Small>
          <Paragraph fontSize={15}>
            <a
              target="_blank"
              href={
                `https://${trade.account.platform}.com/trade/` +
                trade.trade.trade_hash
              }
            >
              {trade.trade.trade_hash}
            </a>
          </Paragraph>
        </Box>
        <Box mb={2}>
          <Small fontSize={12} color="text.secondary">
            {platforms[trade.account.platform].title} Offer Hash
          </Small>
          <Box>
            <a
              href={`https://${trade.account.platform}.com/offer/${trade.trade.offer_hash}`}
              target="_blank"
              rel="noreferrer"
              className="text-primary external-link"
            >
              {trade.trade.offer_hash}
              {/* <span class="external-link-icon svg-icon svg-icon-primary svg-icon-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="16.9497"
                    y="8.46448"
                    width="13"
                    height="2"
                    rx="1"
                    transform="rotate(135 16.9497 8.46448)"
                    fill="currentColor"
                  ></rect>
                  <path
                    d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span> */}
            </a>
          </Box>
        </Box>

        <Box mb={2}>
          <Small fontSize={12} color="text.secondary">
            {platforms[trade.account.platform].title} Fee
          </Small>
          <Paragraph fontSize={15}>
            {formatCurrency(trade.fee, trade.trade.fiat_currency_code)}{" "}
            {trade.trade.fiat_currency_code} (
            {normalizeCrypto(
              trade.trade.fee_crypto_amount,
              trade.trade.crypto_currency_code
            )}{" "}
            {trade.trade.crypto_currency_code})
          </Paragraph>
        </Box>

        <Box mb={1}>
          <Small fontSize={12} color="text.secondary">
            Trade Statistics
          </Small>
          <FlexBox
            mt={1}
            sx={{
              AlignItems: "center",
            }}
          >
            <ATooltip content={`${trade.chat.length} Total Messages`}>
              <Box>
                <ChatIcon
                  active={false}
                  count={trade.chat.length}
                  disabled={true}
                />
              </Box>
            </ATooltip>
            <ATooltip content={`${trade.attachments.length} Total Attachments`}>
              <Box ml={2}>
                <AttachmentIcon
                  active={false}
                  count={trade.attachments.length}
                  disabled={true}
                />
              </Box>
            </ATooltip>
            {trade.otp_verified && (
              <ATooltip id={`kyc-completed`} content={`KYC completed`}>
                <Box ml={2}>
                  <a>
                    <span className="svg-icon svg-icon-muted svg-icon-2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                          fill="currentColor"
                        />
                        <path
                          d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </a>
                </Box>
              </ATooltip>
            )}
            {trade.trade_security && trade.trade_security != "none" && (
              <ATooltip
                content={`Security Type: ${getSecurityType(
                  trade.trade_security
                )}`}
              >
                <span className=" me-1">
                  <i
                    className="fa-solid fa-lock"
                    style={{
                      color: "#2499EF",
                      fontSize: "18px",
                      marginTop: "2px",
                      marginLeft: "10px",
                    }}
                  ></i>
                </span>
              </ATooltip>
            )}
          </FlexBox>
        </Box>

        {trade.trade_security && trade.trade_security !== "none" && (
          <Box mb={2}>
            <Small fontSize={12} color="text.secondary">
              Security Type
            </Small>
            <Paragraph fontSize={15}>
              {trade.trade_security == "pin"
                ? "Trade PIN"
                : "Vendor App Approval"}
            </Paragraph>
          </Box>
        )}

        {trade.status == "released" && (
          <Box mb={2}>
            <Small fontSize={12} color="text.secondary">
              Estimated Profit
            </Small>
            <Paragraph color="success.main" fontSize={15}>
              {formatCurrency(
                trade.estimated_profit,
                trade.trade.fiat_currency_code
              )}{" "}
              {trade.trade.fiat_currency_code}
            </Paragraph>
          </Box>
        )}

        {trade.otp_verified && (
          <Box mb={2}>
            <Small fontSize={12} color="text.secondary">
              Know Your Customer (KYC)
            </Small>
            <Box fontSize={15}>
              {trade.trade.buyer_full_name.first_name}{" "}
              {trade.trade.buyer_full_name?.last_name}
            </Box>
            <FlexBox flexDirection="column">
              {/* <Small mr={1}>
                <PhoneNumberFormatter
                  flag
                  number={trade?.mobile_number}
                  country={"US"}
                />
              </Small> */}
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                <CountryFlag
                  code={trade.mobileLookup.lookup_info.countryCode}
                />
                <span style={{ fontSize: 15, fontWeight: 500 }}>
                  {trade?.mobile_number}
                </span>
              </span>
              <FlexBox
                mb={3}
                p={1}
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "lightgray",
                  borderRadius: "10px",
                  width: "190px",
                }}
              >
                <div className="fs-7">
                  <Paragraph color="black" fontSize={12} fontWeight={500}>
                    {trade.mobileLookup.lookup_info.carrier.name}
                  </Paragraph>
                </div>
                <div>
                  <BadgeMui color="primary.main">
                    {trade.mobileLookup.lookup_info.carrier.type}
                  </BadgeMui>
                </div>
              </FlexBox>
            </FlexBox>
            {/* <Paragraph fontSize={15}>
              SMS verified at {<DateTime date={trade?.otpVerifiedAt} />}
            </Paragraph> */}
          </Box>
        )}

        {trade?.otpVerifiedAt && (
          <Box mb={2}>
            <Paragraph fontSize={12} color="text.secondary">
              KYC Verified At:
            </Paragraph>
            <Paragraph fontSize={15}>
              <FormattedDateTime date={trade?.otpVerifiedAt} />
            </Paragraph>
            {/* <Paragraph fontSize={15}>{trade?.otpVerifiedAt}</Paragraph> */}
            <Small
              sx={{
                width: 100,
                fontSize: 10,
                color: "#fff",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".2rem 1rem",
                backgroundColor: "grey.500",
              }}
            >
              <FormattedDate date={trade?.otpVerifiedAt} />
            </Small>
            <FlexBox onClick={openDrawer}>
              <Paragraph
                fontSize={15}
                color="primary.main"
                style={{ cursor: "pointer" }}
              >
                View KYC history
              </Paragraph>
            </FlexBox>
          </Box>
        )}

        <Box mb={2}>
          <Small fontSize={12} color="text.secondary">
            Rate
          </Small>
          <Paragraph fontSize={15}>
            {formatCurrency(
              trade.crypto_current_rate,
              trade.trade?.fiat_currency_code
            )}{" "}
            {trade.trade.fiat_currency_code}/{trade.trade.crypto_currency_code}
          </Paragraph>
        </Box>

        <Box mb={2}>
          <Small fontSize={12} color="text.secondary">
            Started At
          </Small>
          <Paragraph fontSize={15}>
            <FormattedDateTime date={trade.startedAt} />
          </Paragraph>

          <Small
            sx={{
              width: 100,
              fontSize: 10,
              color: "#fff",
              borderRadius: "4px",
              textAlign: "center",
              padding: ".2rem 1rem",
              backgroundColor: "grey.500",
            }}
          >
            <FromNow date={trade.startedAt} />
          </Small>
        </Box>

        {trade.status !== "paid" && trade.status !== "new" && (
          <Box mb={2}>
            <Small fontSize={12} color="text.secondary">
              {trade.status == "released" ? "Completed At" : "Cancelled At"}
            </Small>
            <Paragraph fontSize={15}>
              <DateTime date={trade.completedAt} />
            </Paragraph>
            <Small
              sx={{
                width: 100,
                fontSize: 10,
                color: "#fff",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".2rem 1rem",
                backgroundColor: "grey.500",
              }}
            >
              <FromNow date={trade.completedAt} />
            </Small>
          </Box>
        )}

        {/* {trade.releasedBy && (
          <Box mb={2}>
            <Small fontSize={12} color="text.secondary">
              Released By
            </Small>
            <Stack direction="row" spacing={2}>
              {trade.releasedBy && <UserSymbol user={trade.releasedBy} />}

              <Box
                sx={{
                  marginLeft: "10px !important",
                }}
              >
                <H6>
                  {trade.releasedBy.first_name +
                    " " +
                    trade.releasedBy.last_name}
                </H6>
                <Tiny fontSize={12} fontWeight={500}>
                  <Badge role={trade.releasedBy.role} />
                </Tiny>
              </Box>
            </Stack>
          </Box>
        )} */}
        {trade.releasedBy !== null && trade.status == "released" && (
          <div>
            <Small fontSize={12} color="text.secondary">
              Released by
            </Small>
            {trade.releasedBy.role === "va" ? (
              <UserInfo user={trade.releasedBy} />
            ) : (
              <UserInfo
                user={trade.releasedBy}
                showOnlineStatus={true}
                showOnline={true}
              />
            )}
          </div>
        )}

        {!trade.releasedBy && trade.status == "released" && trade.is_synced && (
          <>
            <Small fontSize={12} color="text.secondary">
              Synced from
            </Small>
            <Paragraph className="fs-5 d-block">
              {platforms[trade.account.platform].title}
            </Paragraph>
          </>
        )}
        {!trade.releasedBy &&
          trade.status == "released" &&
          !trade.is_synced &&
          trade.trade.offer_type == "sell" && (
            <>
              <Small fontSize={12} color="text.secondary">
                Released by
              </Small>
              <Paragraph>
                Released on {platforms[trade.account.platform].title}
              </Paragraph>
            </>
          )}

        {!trade.releasedBy &&
          trade.status == "released" &&
          !trade.is_synced &&
          trade.trade.offer_type == "buy" && (
            <Box mt={1} className="fs--8">
              <Small fontSize={12} color="text.secondary">
                Released by
              </Small>
              <FlexBox
                sx={{
                  alignItems: "center",
                }}
              >
                {trade?.buyer && (
                  <ProfileImageWithAccount
                    account={trade.buyer}
                    buyer
                    platform={trade.account.platform}
                    platformSize={15}
                  />
                )}
                <Small ml={1} mb={1}>
                  {trade.buyer.username}
                </Small>
              </FlexBox>

              {/* <div className="d-flex align-items-center flex-start mt-1">
                <span className="user-platform-wrapper position-relative">
                  <PaxfulProfilePic account={trade.buyer} size={25} buyer />
                  <Platform
                    platform={trade.account.platform}
                    className="h-15px platform-icon"
                  />
                </span>
                <span className="ms-3">{trade.buyer.username}</span>
              </div> */}
            </Box>
          )}
      </Box>
      <Drawer
        anchor="right"
        open={openRightDrawer}
        onClose={() => setOpenRightDrawer(false)}
      >
        <Box width={300}>
          <AllKyc trade={trade} />
        </Box>
      </Drawer>
    </Card>
  );
}

export default TradeDetail;
