import {
  Badge,
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Paragraph, Small, Tiny } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import ATooltip from "components/shared/ATooltip";
import { FromNow } from "components/shared/FormattedDate";
import { FormattedDateTime } from "components/shared/FormattedDate";
import { getBlockchainTxnUrl } from "components/shared/PaxfulBalance";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { StatusBadge } from "page-sections/hiring-request/ColumnShape";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatCurrency, normalizeCrypto } from "utils/currencies";
import { arrayIsEmpty, copyTextToClipboard } from "utils/utils";
import SouthWestIcon from "@mui/icons-material/SouthWest";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { ArrowOutward } from "@mui/icons-material";
import Platform from "components/shared/Platform";
import NoResultFound from "components/shared/NoResultFound";
import TablePagination from "@mui/material/TablePagination";
import styled from "@emotion/styled";
import platforms from "data/platforms";
import ExternalLink from "components/ExternalLink";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PaxfulAccount from "components/shared/PaxfulAccount";
import { ProfileImageWithAccount } from "components/shared/PartnerDetails";
import { UserContext } from "page-sections/user-list/ViewUser";
import PaginanationAndLimit from "components/shared/PaginanationAndLimit";
import Convert from "icons/Convert";

const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 0",
  color: theme.palette.text.primary,
}));

export const CustomTransactionIcon = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        borderRadius: "50%",
        backgroundColor: "grey.200",
        padding: "5px",
      }}
    >
      {children}
    </Box>
  );
};

export const TransactionIcon = ({ type, currency, currency_from }) => {
  // console.log("currency_from", currency_from);
  let icon = "";
  switch (type) {
    // line-height: 1;
    // font-size: 1rem;
    case "credit":
      icon = (
        <CustomTransactionIcon>
          <SouthWestIcon />
        </CustomTransactionIcon>
      );
      break;
    case "debit":
      icon = (
        <CustomTransactionIcon>
          <NorthEastIcon />
        </CustomTransactionIcon>
      );
      break;
    case "converted":
      icon = (
        <CustomTransactionIcon>
          <CompareArrowsIcon />;
        </CustomTransactionIcon>
      );
      break;
  }

  return (
    <>
      <Box
        style={{
          position: "relative",
        }}
      >
        <span
          style={{
            position: "relative",
          }}
        >
          {icon}
        </span>
        <Box
          style={{
            position: "absolute",
            top: "100%",
            left: "80%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            style={{
              width: "15px",
              height: "15px",
            }}
            src={`/media/svg/coins/${currency.toLowerCase()}.svg`}
            alt=""
          />
        </Box>
        {currency_from && (
          <Box
            style={{
              position: "absolute",
              top: "100%",
              left: "80%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img
              style={{
                width: "15px",
                height: "15px",
              }}
              src={`/media/svg/coins/${currency_from.toLowerCase()}.svg`}
              alt=""
            />
          </Box>
        )}
      </Box>
    </>
  );
};

function TransactionList({
  transactions,
  limit,
  pages,
  pageNum,
  openTradeFromTab,
  handlePageChange,
  handleLimitChange,
  isLoading,
  // getUser,
}) {
  const navigate = useNavigate();

  // Get data from usercontext api

  const { getUser } = useContext(UserContext);

  const openTrade = (userId, tradeHash) => {
    console.log("openTrade called");
    navigate(`/dashboard/users/${userId}?trade=${tradeHash}`);
    getUser();
  };

  return (
    <div className="table-responsive">
      <TableContainer>
        <Table
          sx={{
            borderSpacing: "0 1rem",
            borderCollapse: "separate",
            minWidth: 900,
            backgroundColor: "transparent",
          }}
          className="mb-0 table--hover"
        >
          <TableHead className="text-muted fw-bold fs-7 text-uppercase">
            <TableRow>
              <HeadTableCell>
                {" "}
                <Paragraph
                  fontSize={12}
                  sx={{ fontWeight: "600" }}
                  color={"grey.400"}
                >
                  TRANSACTION
                </Paragraph>
              </HeadTableCell>
              <HeadTableCell>
                <Paragraph
                  fontSize={12}
                  sx={{ fontWeight: "600" }}
                  color={"grey.400"}
                >
                  ACCOUNT
                </Paragraph>
              </HeadTableCell>
              <HeadTableCell>
                <Paragraph
                  fontSize={12}
                  sx={{ fontWeight: "600" }}
                  color={"grey.400"}
                >
                  DETAILS
                </Paragraph>
              </HeadTableCell>
              <HeadTableCell className="text-end pe-10">
                <Paragraph
                  fontSize={12}
                  sx={{
                    fontWeight: "600",
                    // textAlign: "end",
                    // paddingRight: "20px",
                  }}
                  color={"grey.400"}
                ></Paragraph>
              </HeadTableCell>
              <HeadTableCell className="text-end pe-10">
                <Paragraph
                  fontSize={12}
                  sx={{
                    fontWeight: "600",
                    // textAlign: "end",
                    // paddingRight: "20px",
                  }}
                  color={"grey.400"}
                  marginLeft={1}
                >
                  AMOUNT
                </Paragraph>
              </HeadTableCell>
              <HeadTableCell className="text-end pe-10">
                <Paragraph
                  fontSize={12}
                  sx={{ fontWeight: "600" }}
                  color={"grey.400"}
                >
                  STATUS
                </Paragraph>
              </HeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* <PaxfulAccount item={value} size={30} /> */}
            {isLoading ? (
              <TableCell colSpan={10} align="center">
                <CircularProgress />
              </TableCell>
            ) : arrayIsEmpty(transactions) ? (
              <>
                <StyledTableRow>
                  <TableCell colSpan={6} align="center">
                    <NoResultFound />
                  </TableCell>
                </StyledTableRow>
              </>
            ) : (
              <>
                {" "}
                {transactions
                  // .slice(pg * limit, pg * limit + limit)
                  .map((transaction, index) => {
                    let txn_type =
                      transaction.crypto_add_to_balance > 0
                        ? "credit"
                        : "debit";

                    let description;

                    let partner;
                    if (transaction.trade && transaction.trade.buyer) {
                      partner = (
                        <>
                          <Box
                          // sx={{
                          //   display: "inline-block",
                          // }}
                          >
                            <a
                              href={`https://${transaction.account.platform}.com/user/${transaction.trade.buyer.username}`}
                              style={{
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              {/* <ProfileImageWithAccount
                              account={transaction.trade.buyer}
                              buyer={true}
                              pfSize={15}
                              // platform={transaction?.account?.platform}
                              // platformSize={10}
                            /> */}
                              <PaxfulProfilePic
                                size={17}
                                account={transaction.trade.buyer}
                                buyer={true}
                              />{" "}
                              &nbsp;
                              {transaction.trade.buyer.username} &nbsp;
                              {/* <Box
                                sx={{
                                  display: "inline-block",
                                }}
                              > */}
                              <Platform
                                size={19}
                                platform={transaction?.account?.platform}
                              />
                              {/* </Box> */}
                            </a>
                          </Box>
                        </>
                      );
                    }

                    const trade_link = transaction.trade ? (
                      // <Link href={`/trades/${transaction.trade_hash}`}>
                      //   <a>{transaction.trade_hash}</a>
                      // </Link>

                      // <Link
                      //   to={`/dashboard/users/${transaction.user._id}/?trade=${transaction.trade_hash}`}
                      // >
                      //   {transaction.trade_hash}
                      // </Link>
                      <Small
                        onClick={
                          () => openTradeFromTab(transaction.trade_hash)
                          // openTrade(transaction.user._id, transaction.trade_hash)
                        }
                        sx={{
                          cursor: "pointer",
                        }}
                        color={"primary.main"}
                      >
                        {transaction.trade_hash}
                      </Small>
                    ) : (
                      <a
                        href={`https://${transaction.account.platform}.com/trade/${transaction.trade_hash}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {transaction.trade_hash}
                      </a>
                    );

                    switch (transaction.type) {
                      case "Escrow reserve":
                        description = (
                          <>
                            {transaction.trade ? (
                              <FlexBox sx={{ alignItems: "center" }}>
                                <span className="fw-bold fs-6">
                                  New trade for{" "}
                                  {
                                    transaction.trade?.trade
                                      ?.payment_method_name
                                  }
                                </span>
                                <Box ml={0.6}>
                                  {/* <Platform
                                  size={20}
                                  platform={transaction.account.platform}
                                  className={"w-20px ms-2"}
                                /> */}
                                </Box>
                              </FlexBox>
                            ) : (
                              <FlexBox sx={{ alignItems: "center" }}>
                                <span className="fw-bold fs-6">New trade</span>
                                <Box ml={0.6}>
                                  {/* <Platform
                                  size={20}
                                  platform={transaction.account.platform}
                                  className={"w-20px ms-2"}
                                /> */}
                                </Box>
                              </FlexBox>
                            )}
                            <FlexBox sx={{ alignItems: "center" }}>
                              <span className="d-block">
                                Sent to escrow for trade&nbsp; {trade_link}
                                {partner ? " with " : ""}
                                {partner}
                              </span>
                            </FlexBox>
                          </>
                        );
                        break;
                      case "Escrow release":
                        description = (
                          <>
                            {transaction.trade ? (
                              <FlexBox sx={{ alignItems: "center" }}>
                                <span className="fw-bold fs-6">
                                  Trade released for{" "}
                                  {
                                    transaction.trade?.trade
                                      ?.payment_method_name
                                  }
                                </span>
                                <Box ml={0.6}>
                                  {/* <Platform
                                  size={20}
                                  platform={transaction.account.platform}
                                  className={"w-20px ms-2"}
                                /> */}
                                </Box>
                              </FlexBox>
                            ) : (
                              <FlexBox sx={{ alignItems: "center" }}>
                                <span className="fw-bold fs-6">
                                  Trade released
                                </span>
                                <Box ml={0.6}>
                                  {/* <Platform
                                  size={20}
                                  platform={transaction.account.platform}
                                  className={"w-20px ms-2"}
                                /> */}
                                </Box>
                              </FlexBox>
                            )}
                            <span className="d-block text-gray-600">
                              Released{partner ? " to " : ""}
                              {partner} in trade&nbsp;{trade_link}
                            </span>
                          </>
                        );
                        break;
                      case "Escrow return":
                        description = (
                          <>
                            {transaction.trade ? (
                              <FlexBox sx={{ alignItems: "center" }}>
                                <span className="fw-bold fs-6">
                                  Trade cancelled for{" "}
                                  {
                                    transaction.trade?.trade
                                      ?.payment_method_name
                                  }
                                </span>
                                <Box ml={0.6}>
                                  {/* <Platform
                                  size={20}
                                  platform={transaction.account.platform}
                                  className={"w-20px ms-2"}
                                /> */}
                                </Box>
                              </FlexBox>
                            ) : (
                              <FlexBox sx={{ alignItems: "center" }}>
                                <span className="fw-bold fs-6">
                                  Trade cancelled
                                </span>
                                <Box ml={0.6}>
                                  {/* <Platform
                                  size={20}
                                  platform={transaction.account.platform}
                                  className={"w-20px ms-2"}
                                /> */}
                                </Box>
                              </FlexBox>
                            )}
                            <span className="d-block text-gray-600">
                              Returned to your wallet from trade&nbsp;
                              {trade_link}
                              {partner ? " with " : ""}
                              {partner}
                            </span>
                          </>
                        );
                        break;
                      case "Received":
                        description = (
                          <>
                            <FlexBox className="fw-bold fs-6 d-flex">
                              Crypto deposit received{" "}
                              <Box ml={0.6}>
                                {/* <Platform
                                size={20}
                                platform={transaction.account.platform}
                                className={"w-20px ms-2"}
                              /> */}
                              </Box>
                            </FlexBox>

                            <div className="transaction-sent text-gray-600">
                              Received{" "}
                              {/* <CopyButton
                              className="has-copy-icon d-flex text-gray-900"
                              onClick={() => {
                                copyTextToClipboard(transaction.sent_to);
                              }}
                              iconClass="copy-icon text-hover-primary"
                            >
                              <span className="me-2">
                                {transaction.sent_to}
                              </span>
                            </CopyButton> */}
                              {/* <span className="me-2">
                                {getSubString(transaction.sent_to)}
                              </span> */}
                              {normalizeCrypto(
                                transaction?.amount_crypto,
                                transaction?.crypto_currency_code,
                                "gwei"
                              )}{" "}
                              {transaction.crypto_currency_code}
                              <img
                                src={`/media/svg/coins/${transaction.crypto_currency_code.toLowerCase()}.svg`}
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "0px 5px 0px 5px",
                                }}
                                className="ms-1 mb-1"
                              />
                              {/* <IconButton
                                size="small"
                                fontSize={20}
                                onClick={() =>
                                  copyTextToClipboard(transaction.sent_to)
                                }
                                // color={isCopied ? "primary" : "default"}
                              >
                                <FileCopyIcon fontSize="xs" />
                              </IconButton> */}
                              <ExternalLink
                                link={getBlockchainTxnUrl(
                                  transaction.tx_id,
                                  transaction.blockchain
                                )}
                                label={"View Transaction"}
                              />
                            </div>
                          </>
                        );
                        break;
                      case "Sent out":
                        description = (
                          <>
                            <FlexBox className="fw-bold fs-6 d-flex">
                              Sent out{" "}
                              <Box ml={0.6}>
                                {/* <Platform
                                size={20}
                                platform={transaction.account.platform}
                                className={"w-20px ms-2"}
                              /> */}
                              </Box>
                            </FlexBox>

                            <Box className="transaction-sent text-gray-600 d--flex">
                              Sent to{" "}
                              {/* <CopyButton
                              className="has-copy-icon d-flex text-gray-900"
                              onClick={() => {
                                copyTextToClipboard(transaction.sent_to);
                              }}
                              iconClass="copy-icon text-hover-primary"
                            >
                              <span className="me-2">
                                {transaction.sent_to}
                              </span>
                            </CopyButton> */}
                              {transaction.sent_to}
                              <IconButton
                                size="small"
                                onClick={() =>
                                  copyTextToClipboard(transaction.sent_to)
                                }
                                // color={isCopied ? "primary" : "default"}
                              >
                                <FileCopyIcon fontSize="xs" />
                              </IconButton>
                              <ExternalLink
                                link={getBlockchainTxnUrl(
                                  transaction.tx_id,
                                  transaction.blockchain
                                )}
                                label={"View Transaction"}
                              />
                            </Box>
                          </>
                        );
                        break;
                      case "Cryptocurrency purchased":
                        description = (
                          <>
                            <FlexBox className="fw-bold fs-6 d-flex">
                              Cryptocurrency purchased
                              <Box ml={0.6}>
                                {/* <Platform
                                size={20}
                                platform={transaction.account.platform}
                                className={"w-20px ms-2"}
                              /> */}
                              </Box>
                            </FlexBox>
                            <span className="d-block text-gray-600">
                              Received from trade&nbsp; {trade_link}
                              {partner ? " with " : ""}
                              {partner}
                            </span>
                          </>
                        );
                        break;
                      case "Received (external)":
                        description = (
                          <>
                            <FlexBox className="fw-bold fs-6 d-flex">
                              Crypto deposit received
                              <Box ml={0.6}>
                                {/* <Platform
                                size={20}
                                platform={transaction.account.platform}
                                className={"w-20px ms-2"}
                              /> */}
                              </Box>
                            </FlexBox>

                            <Box className="transaction-sent text-gray-600 d--flex">
                              {/* <CopyButton
                              className='has-copy-icon d-flex text-gray-900 ms-1'
                              onClick={() => {
                                copyTextToClipboard(transaction.tx_id);
                              }}
                              iconClass='copy-icon text-hover-primary'
                            >
                              <span className='me-2'>{transaction.tx_id}</span>
                            </CopyButton> */}
                              {/* {transaction.sent_to}
                              <IconButton
                                size="small"
                                onClick={() =>
                                  copyTextToClipboard(transaction.sent_to)
                                }
                                // color={isCopied ? "primary" : "default"}
                              >
                                <FileCopyIcon fontSize="xs" />
                              </IconButton> */}
                              Received{" "}
                              {normalizeCrypto(
                                transaction?.amount_crypto,
                                transaction?.crypto_currency_code,
                                "gwei"
                              )}{" "}
                              {transaction.crypto_currency_code}
                              <img
                                src={`/media/svg/coins/${transaction.crypto_currency_code.toLowerCase()}.svg`}
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "0px 5px 0px 5px",
                                }}
                                className="ms-1 mb-1"
                              />
                              <ExternalLink
                                link={getBlockchainTxnUrl(
                                  transaction.tx_id,
                                  transaction.blockchain
                                )}
                                label={"View Transaction"}
                              />
                            </Box>
                          </>
                        );
                        break;
                      case "Funds converted":
                        txn_type = "converted";
                        description = (
                          <>
                            <FlexBox className="fw-bold fs-6 d-flex">
                              Funds converted
                              <Box ml={0.6}>
                                {/* <Platform
                                size={20}
                                platform={transaction.account.platform}
                                className={"w-20px ms-2"}
                              /> */}
                              </Box>
                            </FlexBox>
                            <span
                              style={{ alignItems: "center" }}
                              className="d-block text-gray-600"
                            >
                              Funds converted from{" "}
                              {normalizeCrypto(
                                transaction.from_amount_crypto,
                                transaction.from_crypto_currency_code,
                                "gwei"
                              )}{" "}
                              {transaction?.from_crypto_currency_code}
                              <img
                                src={`/media/svg/coins/${transaction?.from_crypto_currency_code.toLowerCase()}.svg`}
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "0px 5px 0px 5px",
                                }}
                                className="ms-1 mb-1 me-1"
                              />
                              to{" "}
                              {normalizeCrypto(
                                transaction?.amount_crypto,
                                transaction?.crypto_currency_code,
                                "gwei"
                              )}{" "}
                              {transaction.crypto_currency_code}
                              <img
                                src={`/media/svg/coins/${transaction.crypto_currency_code.toLowerCase()}.svg`}
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "0px 5px 0px 5px",
                                }}
                                className="ms-1 mb-1"
                              />
                            </span>
                          </>
                        );
                        break;
                      case "Received (internal)":
                        description = (
                          <>
                            {/* <FlexBox className="fw-bold fs-6 d-flex">
                              Recieved (internal)
                              <Box ml={0.6}>
                               
                              </Box>
                            </FlexBox>
                            <span className="d-block text-gray-600">
                              Recieved internal
                            </span> */}
                            <span className="fw-bold fs-6 d-flex text-gray-800">
                              Received internally from another{" "}
                              {platforms[transaction.account.platform].title}{" "}
                              user
                            </span>
                            <div className="transaction-sent text-gray-700 d--flex">
                              Received{" "}
                              {normalizeCrypto(
                                transaction?.amount_crypto,
                                transaction?.crypto_currency_code,
                                "gwei"
                              )}{" "}
                              {transaction.crypto_currency_code}
                              <img
                                src={`/media/svg/coins/${transaction.crypto_currency_code.toLowerCase()}.svg`}
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "0px 5px 0px 5px",
                                }}
                                className="ms-1 mb-1"
                              />
                            </div>
                          </>
                        );

                        break;

                      case "Sent out (internal)":
                        description = (
                          <>
                            <FlexBox className="fw-bold fs-6 d-flex">
                              Sent out (internal)
                              <Box ml={0.6}>
                                {/* <Platform
                                size={20}
                                platform={transaction.account.platform}
                                className={"w-20px ms-2"}
                              /> */}
                              </Box>
                            </FlexBox>
                            <span className="d-block text-gray-600">
                              Sent out internal
                            </span>
                          </>
                        );
                        break;
                      case "Sent out (external)":
                        description = (
                          <>
                            <FlexBox className="fw-bold fs-6 d-flex">
                              Sent out (external)
                              <Box ml={0.6}>
                                {/* <Platform
                                size={20}
                                platform={transaction.account.platform}
                                className={"w-20px ms-2"}
                              /> */}
                              </Box>
                            </FlexBox>

                            <div className="transaction-sent text-gray-600 d--flex">
                              {" "}
                              <Paragraph fontSize={12} color="text.secondary">
                                Sent out (external)
                              </Paragraph>
                            </div>
                          </>
                        );
                        break;
                      case "Reserved for conversion":
                        description = (
                          <>
                            <FlexBox className="fw-bold fs-6 d-flex">
                              Reserved for conversion
                              <Box ml={0.6}>
                                {/* <Platform
                                size={20}
                                platform={transaction.account.platform}
                                className={"w-20px ms-2"}
                              /> */}
                              </Box>
                            </FlexBox>
                            <span className="d-block text-gray-600">
                              Reserved for conversion
                            </span>
                          </>
                        );
                        break;
                      case "Dispute won by seller":
                        description = (
                          <>
                            <FlexBox className="fw-bold fs-6 d-flex">
                              Dispute won by seller
                              <Box ml={0.6}>
                                {/* <Platform
                                size={20}
                                platform={transaction.account.platform}
                                className={"w-20px ms-2"}
                              /> */}
                              </Box>
                            </FlexBox>
                            <span className="d-block text-gray-600">
                              Dispute won. Returned from dispute.
                            </span>
                          </>
                        );
                        break;
                      case "Security deposit deposited":
                        description = (
                          <>
                            <FlexBox className="fw-bold fs-6 d-flex">
                              Security Deposit
                              <Box ml={0.6}>
                                {/* <Platform
                                size={20}
                                platform={transaction.account.platform}
                                className={"w-20px ms-2"}
                              /> */}
                              </Box>
                            </FlexBox>
                            {transaction.account && (
                              <span className="d-block text-gray-600">
                                Sent to{" "}
                                {platforms[transaction.account.platform].title}{" "}
                                for security deposit.
                              </span>
                            )}
                          </>
                        );
                        break;

                      case "Security deposit retrieved":
                        description = (
                          <>
                            <FlexBox className="fw-bold fs-6 d-flex">
                              Security Deposit Retrieved
                              <Box ml={0.6}>
                                {/* <Platform
                                size={20}
                                platform={transaction.account.platform}
                                className={"w-20px ms-2"}
                              /> */}
                              </Box>
                            </FlexBox>
                            {transaction.account && (
                              <span className="d-block text-gray-600">
                                Security deposit retrieved from{" "}
                                {platforms[transaction.account.platform].title}.
                              </span>
                            )}
                          </>
                        );
                        break;
                    }

                    return (
                      <StyledTableRow
                        key={index}
                        style={{ verticalAlign: "middle" }}
                        // onClick={() => {}}
                        className="transaction-data"
                      >
                        <BodyTableCell>
                          {/* <strong className="mb-label">Transaction</strong> */}
                          <FlexBox sx={{ flexDirection: "column" }}>
                            <FormattedDateTime date={transaction.time} />
                            {/* <StatusBadge
                              color={"grey.300"}
                              title={<FromNow date={transaction.time} />}
                            /> */}

                            <Small
                              sx={{
                                width: 100,
                                fontSize: 10,
                                color: "#fff",
                                borderRadius: "4px",
                                textAlign: "center",
                                padding: ".2rem 1rem",
                                backgroundColor: "lightgrey",
                                color: "black",
                              }}
                            >
                              <FromNow date={transaction.time} />
                            </Small>
                          </FlexBox>{" "}
                        </BodyTableCell>

                        <BodyTableCell>
                          <PaxfulAccount item={transaction.account} size={30} />
                        </BodyTableCell>
                        <BodyTableCell>
                          {/* <strong className="mb-label">Description</strong> */}
                          <Box
                            sx={{
                              maxWidth: "350px",
                              wordBreak: "break-word",
                            }}
                          >
                            {description}
                          </Box>
                        </BodyTableCell>
                        <BodyTableCell>
                          {
                            {
                              credit: (
                                <Small
                                  sx={{
                                    backgroundColor: "success.main",
                                    padding: "1px 5px",
                                    borderRadius: "5px",
                                    fontWeight: "bold",
                                  }}
                                  className="badge badge-light-success fs-7 fw-bolder"
                                >
                                  +
                                </Small>
                              ),
                              debit: (
                                <Small
                                  sx={{
                                    backgroundColor: "error.main",
                                    padding: "0px 6px",
                                    borderRadius: "5px",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                  className="badge badge-light-danger fs-7 fw-bolder"
                                >
                                  -
                                </Small>
                              ),
                              converted: <Convert />,
                            }[txn_type]
                          }
                        </BodyTableCell>

                        <BodyTableCell style={{ minWidth: "200px" }}>
                          {/* <strong className="mb-label">Amunt</strong> */}
                          <FlexBox sx={{ flexDirection: "column" }}>
                            <Box mr={2}>
                              <Paragraph
                                fontSize={14}
                                sx={{ fontWeight: "600" }}
                                color={"grey.600"}
                              >
                                {/* <span>
                                  {txn_type == "credit" ||
                                  txn_type == "converted"
                                    ? "+"
                                    : "-"}
                                </span> */}
                                <span className="text-middle">
                                  <img
                                    src={`/media/svg/coins/${transaction.crypto_currency_code.toLowerCase()}.svg`}
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      margin: "0px 5px 0px 5px",
                                    }}
                                    className="ms-1 mb-1"
                                  />
                                  {normalizeCrypto(
                                    transaction.amount_crypto,
                                    transaction.crypto_currency_code,
                                    "gwei"
                                  )}{" "}
                                  {transaction.crypto_currency_code}
                                </span>
                              </Paragraph>
                            </Box>
                            {txn_type == "converted" && (
                              <small
                                style={{
                                  marginLeft: "10px",
                                }}
                                className="text-gray-800 d-block"
                              >
                                <span>-</span>
                                {normalizeCrypto(
                                  transaction.from_amount_crypto,
                                  transaction.from_crypto_currency_code,
                                  "gwei"
                                )}{" "}
                                {transaction.from_crypto_currency_code}
                              </small>
                            )}
                            {transaction.amount_fiat && (
                              <Box mr={2} mt={0.5}>
                                {" "}
                                <Paragraph fontSize={12} ml={1}>
                                  <span>
                                    {txn_type == "credit" ? "+" : "-"}
                                  </span>
                                  {formatCurrency(
                                    transaction.amount_fiat < 0
                                      ? -transaction.amount_fiat
                                      : transaction.amount_fiat,
                                    transaction.fiat_currency_code
                                      ? transaction.fiat_currency_code
                                      : "USD"
                                  )}
                                </Paragraph>
                              </Box>
                            )}{" "}
                          </FlexBox>
                        </BodyTableCell>
                        <BodyTableCell>
                          <Small
                            sx={{
                              width: 100,
                              fontSize: 10,
                              color: "#fff",
                              borderRadius: "5px",
                              textAlign: "center",
                              padding: ".3rem .2rem",
                              backgroundColor: "success.main",
                              fontWeight: "800",
                              fontSize: "10px",
                            }}
                          >
                            {"Completed"}
                          </Small>
                        </BodyTableCell>
                      </StyledTableRow>
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {transactions.length > 0 && (
        // <TablePagination
        //   rowsPerPageOptions={[25, 50, 75, 100]}
        //   component="div"
        //   count={transactions.length}
        //   rowsPerPage={limit}
        //   page={pg}
        //   onPageChange={handleChangePage}
        //   onRowsPerPageChange={handleChangeRowsPerPage}
        // />
        <PaginanationAndLimit
          tableData={transactions}
          handleChange={handlePageChange}
          count={pages}
          currentPage={pageNum}
          handleLimitChange={handleLimitChange}
          limit={limit}
        />
      )}
    </div>
  );
}

export default TransactionList;

export function getSubString(data) {
  const firstSeven = data.slice(0, 7);
  const lastFive = data.slice(-5);
  return `${firstSeven}...${lastFive}`;
}
